import React, {useEffect} from "react";
import privacyHeaderImage from "../../assets/img/privacy-image-white.png";
import styles from "./privacy-statement.module.css";
const PrivacyStatement = () => {
  useEffect(() => {
    window.scrollTo({top: 0, behavior: "smooth"});
  });

  return (
    <div>
      <div className="bg-primary m-5 flex flex-col items-center justify-between py-4">
        <img src={privacyHeaderImage} alt="" className={styles.privacyImg} />
        <h1 className="text-4xl font-bold text-white   pb-2">
          Privacy Statement
        </h1>
      </div>
      <div className="max-w-6xl py-4 m-5 md:m-auto">
        <h2 className="text-xl font-bold opacity-90">
          Top Gun Automobiles Privacy Notice :-
        </h2>
        <p className="my-5">
          <strong className="opacity-90">Top Gun Automobiles</strong> and its affiliates or
          subsidiaries (Top Gun Automobiles  , we, us, our”) believe in being transparent with
          respect to the personal information that we process. This Privacy
          Notice governs how we may process data that is reasonably capable of
          being linked to an individual (“you”), such as name, address,
          telephone number, email address, as well as certain data associated
          with your browser or device (“personal information”) collected through
          the https://topgunautomobiles.com/ website, mobile and other
          applications owned and operated by us (“digital property(ies)”).
          Please read this Privacy Notice carefully as it describes the purposes
          to which we may process your personal information. Depending on where
          you live and how you use our digital properties, additional state
          specific information is included below that may apply to you.
        </p>
        <h5 className="font-bold opacity-90">Contents :-</h5>
        <ul className="list-[lower-roman] px-4 py-2">
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#first">
              Categories of Personal Information We May Collect
            </a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#second">How We Collect Personal Information</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#third">Other Ways Personal Information is Collected</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#fourth"> How We Use Your Personal Information</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#fifth">
              With Whom We Share Your Personal Information and Why
            </a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#sixth">
              Technologies We Use to Automatically Collect Personal Information
            </a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#seventh"> Additional U.S. State Disclosures</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#eighth"> Your Privacy Rights and Choices</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#ninth">Publicly Posted Information</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#tenth">Storing and Retaining Your Personal Information</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#eleventh">
              Security - Steps we take to protect your personal information
            </a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#twelve">International Transfer</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#thirteen">Children</a>
          </li>
          <li className="text-blue-700 opacity-80 cursor-pointer">
            <a href="#fourthteen">Changes to this Privacy Notice</a>
          </li>
        </ul>

        <div id="first" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold">
            I. Categories of Personal Information We May Collect
          </h1>
          <p className="mt-3">
            <strong className="opacity-90">
              We may collect the following categories of personal information:
            </strong>
            <br />
            <br />
            <strong className="opacity-90">Registration information</strong>
            <br />
            When you create an account or subscribe to one of our services, we
            may collect information, such as your first and last name, country
            of residence, email address, username, and password.
            <br />
            <br />
            <strong className="opacity-90">Transaction information</strong>
            <br />
            This information is processed when you engage with certain services
            on our digital properties, and may include your postal address,
            telephone number, and payment information.
            <br />
            <br />
            <strong className="opacity-90">User Activity Information</strong>
            <br />
            This may include information about your use, and the use by any
            person(s) you authorize through your account, of our digital
            properties, such as the content you view or post, how often you use
            our services, and your preferences. This can also include
            information you post in public forums on our digital properties,
            such as your forum posts or blog comments. It could also include
            certain information provided using our messaging chat or other
            similar services where we are permitted by applicable law to collect
            this information.
            <br />
            <br />
            <strong className="opacity-90">Third-Party Information</strong>
            <br />
            This includes insights about the use of our digital properties
            whether hosted by us, or on third-party platforms or devices.
            <br />
            <br />
            <strong className="opacity-90">Location information</strong>
            <br />
            This may include location information provided by a mobile or other
            device interacting with our digital properties (including through
            beacon technologies), or associated with your IP address, where we
            are permitted by law to process this information.
            <br />
            <br />
            <strong className="opacity-90">Technical Data</strong>
            <br />
            Usage, viewing, technical, and device data when you visit our
            digital properties whether hosted by Top Gun Automobiles or third-party sites or
            platforms, or open emails we send, including your browser or device
            type, unique device identifier, and IP address.
          </p>
        </div>

        <div id="second" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold">
            II. How We Collect Personal Information
          </h1>
          <p className="mt-3">
            The following describes the different ways we might collect your
            personal information, whether from you directly or automatically
            when interacting with our digital properties.
            <br />
            <br />
            <strong className="opacity-90">
              a. Personal Information You Share Directly
            </strong>
            <br />
            <strong className="opacity-90">
              When Requesting to be Contacted by a Car Seller
            </strong>
            <br />
            If you request to be contacted by a car seller, both Top Gun Automobiles and the
            seller may collect personal information provided by you through the
            text and chat functionalities that we provide and on the contact
            forms on our site, which may include your name, email address,
            postal code, and telephone number, so that the seller can contact
            you to consider a car purchase or request that you submit a dealer
            review.
            <br />
            <br />
            <strong className="opacity-90">
              When Using Our Site to Buy or Sell a Vehicle
            </strong>
            <br />
            If you are an individual user (and not acting on behalf of a car
            dealer) and you want to use our services to buy or sell a vehicle,
            we and/or our business partners may collect information provided by
            you in connection with that transaction, for example to create your
            vehicle listing, to verify vehicle ownership, to confirm funds
            availability, to process payment, to populate purchase and sale
            documents, and to provide user support. We may collect this
            information from entry forms on our site or third-party sites we
            integrate with, or from a picture of your ID that you upload. This
            information may include your name, address, email address, telephone
            number, driver's license number, photo, gender, date of birth, the
            last four digits of your social security number, bank name, and bank
            account and routing numbers.
            <br />
            <br />
            <strong className="opacity-90">
              When Registering with Top Gun Automobiles  ; Subscribing
            </strong>
            <br />
            If you register with us or subscribe to receive specific information
            or services on our site, we will also collect registration
            information, such as name, email address, mailing address, telephone
            number, username and password.
            <br />
            <br />
            Registering with us also gives you the ability to personalize your
            Top Gun Automobiles site experience. The benefits of registering will increase
            over time as we get to know you better and introduce new features.
            We encourage you to register so that you can experience everything
            that Top Gun Automobiles has to offer!
            <br />
            <br />
            <strong className="opacity-90">
              b. Automatic Collection of Personal Information
            </strong>
            <br />
            In addition to the personal information we may collect from you
            directly, we also collect data from your browser or device using
            tracking technologies (See VI. Technologies We Use to Automatically
            Collect Personal Information) that is considered personal
            information when you engage with our digital properties. We
            automatically collect certain browser or device technical data in
            order to deliver our services to you, and to help make sure our
            digital properties are functioning properly. This typically includes
            data about your browser or device's IP address, user agent string,
            your internet service provider or mobile carrier and the type of
            handheld or mobile device you use.
          </p>
        </div>

        <div id="third" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold">
            III. Other Ways Personal Information is Collected
          </h1>
          <p className="mt-3">
            <strong className="opacity-90">
              a. Applications and Widgets for Social Media
            </strong>
            <br />
            We may display applications or widgets from social media providers
            that allow interaction or content sharing by their users. These
            widgets, such as the Facebook® "Share" or "Like" button, are visible
            to you on the page that you visit. This may allow the social media
            networks in which you participate to collect information about you,
            even when you do not explicitly activate the network's application
            or widget while on our digital property. Please visit the applicable
            social media network's privacy notice to better understand their
            data collection practices and the choices they make available to
            you.
            <br />
            <br />
            In addition to social media networks, our digital properties also
            contain references and links to third-party sites that may offer
            information of interest. This Privacy Notice does not apply to those
            sites, and we recommend reviewing those sites' privacy notices
            individually.
            <br />
            <br />
            <strong className="opacity-90">
              b. Information Collected from Other Sources
            </strong>
            <br />
            We may process personal information from third-party sources to
            update or supplement the information that you provide or that we
            collect. For instance, we may collect vehicle registration
            information from public databases to support a service you have
            engaged us for. Other sources of information include third-party
            advertising partners, data providers or aggregators, and search
            information providers.
            <br />
            <br />
            We use this information to help us maintain the accuracy of the
            information we collect, personalize your experience with the site,
            target our communications and advertisements so that we can inform
            you of products and services or other offers that may be of interest
            to you, provide private purchase and sale transaction services,
            prevent fraud, and for internal business analysis or other business
            purposes. To learn more about our advertising activities, we
            encourage you to review our Interest-Based Ads Policy, and our
            section on Targeted Advertising below.
          </p>
        </div>

        <div id="fourth" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold">
            IV. How We Use Your Personal Information
          </h1>
          <p className="mt-3">
            <strong className="mt-3">
              We may process your personal information in order to:
            </strong>
            <br />
            <br />
            - send information to car sellers to complete transactions per your
            instruction;
            <br />
            - complete your transaction(s) with us;
            <br />
            - process and collect your payments;
            <br />
            - send you promotional marketing material about us and our services;
            <br />
            - customize, analyze, adjust and improve the site;
            <br />
            - provide you with important administrative information regarding
            the site, such as changes to this Privacy Notice and our Terms of
            Use and other policies;
            <br />
            - prevent fraud and other prohibited or illegal activities;
            <br />
            - comply with requests from law enforcement or relevant data
            protection agencies;
            <br />
            - provide technical and customer support;
            <br />
            - seek your opinion or feedback on our services or industry
            questions;
            <br />
            - facilitate access to our applications to which you have
            subscribed;
            <br />
            - enforce our legal rights or comply with legal requirements;
            <br />
            - provide improved website and product experience and
            communications; or,
            <br />
            - comply with a legal or regulatory obligation.
            <br />
            <br />
            See also, VI. Technologies We Use to Automatically Collect Personal
            Information below.
          </p>
        </div>

        <div id="fifth" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold">
            V. With Whom We Share Your Personal Information and Why
          </h1>
          <p className="mt-3">
            <strong className="opacity-90">
              Mainly to provide our services, we may share your personal
              information with the following entities:
            </strong>
            <br />
            <br />
            <strong className="opacity-90">Affiliates</strong>
            <br />
            We may share information about you, including personal information,
            with our corporate affiliates for specific business purposes.
            <br />
            <br />
            <strong className="opacity-90">
              Car Sellers and Manufacturers/Requests
            </strong>
            <br />
            Upon your instruction, we may share your personal information with
            car sellers and manufacturers who use that information for purposes
            of marketing their own products or services to you directly.
            <br />
            <br />
            <strong className="opacity-90">Lead generation</strong>
            <br />
            When you submit your e-mail address and/or phone number to a
            particular seller through the site, you agree to being contacted by
            the seller, including by phone at the number provided, text message,
            email, automatic telephone dialing system and/or an artificial or
            pre-recorded voice.
            <br />
            <br />
            <strong className="opacity-90">Vendors</strong>
            <br />
            We may provide third-party vendors (such as market research firms,
            marketing partners, advertising agencies, and payment processing
            partners) access to your personal information to perform services on
            our behalf. We contractually require our vendors to protect and
            limit the use of such personal information solely for the purposes
            of providing the specified services on our behalf.
            <br />
            <br />
            <strong className="opacity-90">
              Other Providers of Products and Services
            </strong>
            <br />
            Upon your direction, we may disclose your personal information to
            other third-party providers of products and services (for example
            financing providers). We contractually require such providers to
            protect your personal information and to limit the use of such
            information for the purposes you agreed to when submitting such
            personal information on our site. By submitting personal information
            on our site in connection with receiving products and/or services
            from these providers, you acknowledge that your request to have your
            personal information transmitted to such providers means it will be
            subject to their privacy notices, and that you should contact them
            directly for more information concerning their personal information
            processing activities.
            <br />
            <br />
            <strong className="opacity-90">Legal Authorities</strong>
            <br />
            We cooperate with legal authorities and may in some instances be
            required to disclose personal information in response to requests
            from law enforcement authorities, or in response to a subpoena or
            other legal process. We also share information about you if we
            believe we should in order to: (i) prevent a violation of the law;
            (ii) protect or defend our interests and our legal rights or
            property; (iii) protect the rights, interests, safety and security
            of users of the site or members of the public; (iv) protect against
            fraud or for risk management purposes; or (v) comply with other
            applicable law or legal process. We also may share your information
            in connection with a corporate transaction, such as a divestiture,
            merger, consolidation, or asset sale, and in the unlikely event of
            bankruptcy.
            <br />
            <br />
            See{" "}
            <strong className="opacity-90">
              VIII. Your Privacy Rights and Choices
            </strong>{" "}
            below to learn about how you can exercise your privacy rights with
            Top Gun Automobiles  .
          </p>
        </div>

        <div id="sixth" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold">
            VI. Technologies We Use to Automatically Collect Personal
            Information
          </h1>
          <p className="mt-3">
            <strong className="opacity-90">
              Along with companies we partner with, we employ pixel tags,
              cookies, java scripts, SDKs, local shared objects such as Flash
              (sometimes called “flash cookies”), and similar technology
              (“tracking technologies”) on our digital properties, and
              third-party digital properties we integrate with.
            </strong>{" "}
            These technologies may create unique advertising identifiers (e.g.
            cookie identifiers or mobile advertising identifiers) that reside on
            your browser or mobile device, and in some cases server-side.
            Tracking technologies may transmit data about your use of our
            digital properties, including which pages you interacted with, your
            vehicle search history, the advertisements shown to you or that you
            may have clicked on, other page view/click data, the date and time
            of your visit, all associated with your browser or mobile device's
            advertising identifier. These identifiers typically persist over
            time. You can set some cookie preferences through your device or
            browser settings but doing so may affect the functionality of the
            site.
            <br />
            <br />
            To learn more about how we employ these technologies and the choices
            you have concerning our processing of your personal information for
            digital advertising purposes, see{" "}
            <strong className="opacity-90">
              VIII. Your Privacy Rights and Choices
            </strong>{" "}
            below.
            <br />
            <br />
          </p>
          <strong className="opacity-90">
            Consistent with IV. How We Use Your Personal Information above, the
            purposes for which we use tracking technologies include:
          </strong>
          <br />
          <br />
          <table border="1" cellpadding="10">
            <thead>
              <tr>
                <th>
                  <strong className="opacity-90">Purpose</strong>
                </th>
                <th>
                  <strong className="opacity-90">Explanation</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400s">
                <td>
                  <strong className="opacity-90">Processes</strong>
                </td>
                <td>
                  Make digital properties work in the way that it is expected.
                  For example, we use a cookie that tells us whether you have
                  already signed up for an account.
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="opacity-90">
                    Authentication, Security, and Compliance
                  </strong>
                </td>
                <td className="py-8">
                  Prevent fraud, protect user data from unauthorized parties,
                  and for compliance with legal requirements. For example, we
                  use cookies to determine if a suspicious IP address (based on
                  geography) is logged in through your account.
                </td>
              </tr>
              <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
                <td>
                  <strong className="opacity-90">Preferences</strong>
                </td>
                <td>
                  Track how our digital properties are used and remember
                  preferences. For example, we monitor and store your browsing
                  activity to customize your experience and better understand
                  your vehicle shopping interests.
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="opacity-90">Notifications</strong>
                </td>
                <td className="py-8">
                  Allow notices of information or options that we think could
                  improve the use of the site or app. For example, send out
                  notifications of price drops in a vehicle that you previously
                  showed interest in.
                </td>
              </tr>
              <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
                <td>
                  <strong className="opacity-90">Advertising</strong>
                </td>
                <td>
                  Make advertising more relevant to users and more valuable to
                  our advertisers. For example, we may use cookies to serve you
                  targeted ads, such as ads that are displayed to you based on
                  your visits to other websites, or to tell us if you have
                  recently clicked on an ad. See{" "}
                  <strong className="opacity-90">
                    VIII. Your Privacy Rights and Choices
                  </strong>{" "}
                  below.
                </td>
              </tr>
              <tr>
                <td>
                  <strong className="opacity-90">Analytics</strong>
                </td>
                <td className="py-8">
                  Intended to help us understand how visitors use our digital
                  properties. For example, we use a cookie that tells us how our
                  search suggestions correlate to your browsing while on the
                  site or app.
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div id="seventh" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold mb-3">
            VII. Additional U.S. State Disclosures
          </h1>
          <strong className="opacity-90">
            This section supplements the above by providing additional
            information about how we may process your personal information.
            Unless stated otherwise, all terms defined in our Privacy Notice
            have the same meaning below.
          </strong>
          <br />
          <br />
          <strong className="opacity-90">Sensitive Information</strong>
          <br />
          In order to support the services we provide, we may process the
          following types of personal information, which are generally
          considered sensitive under certain U.S. state privacy laws:
          <br />
          <br />
          <ul>
            <li>
              Social security number, driver's license number, and passport
              number;
            </li>
            <li>
              Credit/debit card number plus expiration date and security code
              (CVV), and financial account number and routing number;
            </li>
            <li>Username and password; and</li>
            <li>Precise geolocation data.</li>
          </ul>
          We may process this information for the purposes set out above (See
          IV. How We Use Your Personal Information), consistent with our
          obligations under applicable law.
          <br />
          <br />
          <strong className="opacity-90">
            De-Identified or Aggregate Information
          </strong>
          <br />
          We may at times receive or process personal information to create data
          sets that can no longer reasonably be used to infer information about,
          or otherwise be linked to, a particular individual or household. Where
          we maintain de-identified or aggregate information, we will maintain
          and use the data in de-identified or aggregate form and not attempt to
          re-identify the data.
          <br />
          <br />
          <strong className="opacity-90">a. California</strong>
          <br />
          We share this notice of collection to comply with the California
          Consumer Privacy Act of 2018 (CCPA). Any terms defined in the CCPA
          have the same meaning when used in this Privacy Notice.
          <br />
          <br />
          <strong className="opacity-90">Sharing Personal Information</strong>
          <br />
          Top Gun Automobiles does not generally sell information as the term “sell” is
          traditionally understood. However, to the extent a “sale” under the
          CCPA is interpreted to include advertising technology activities such
          as those disclosed in this notice as a “sale,” we comply with
          applicable law as to such activity.
          <br />
          <br />
          <strong className="opacity-90">Information We Collect:</strong>
          <br />
          <br />
          <table border="1" cellpadding="10">
            <thead>
              <tr>
                <th>
                  <strong className="opacity-90">Categories</strong>
                </th>
                <th>
                  <strong className="opacity-90">Examples</strong>
                </th>
                <th>
                  <strong className="opacity-90">Do we sell?</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
                <td>
                  <strong className="opacity-90">Identifiers</strong>
                </td>
                <td>
                  Name, alias, postal address, unique personal identifier,
                  online identifier, Internet Protocol (IP) address, email
                  address, account name, social security number, driver's
                  license number, or other similar identifiers
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>
                  <strong className="opacity-90">
                    Customer records information
                  </strong>
                </td>
                <td className="py-8">
                  Name, signature, social security number, physical
                  characteristics or description, address, telephone number,
                  driver's license or state identification card number, bank
                  account number, credit or debit card number, other financial
                  information
                </td>
                <td>Yes</td>
              </tr>
              <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
                <td>
                  <strong className="opacity-90">
                    Characteristics of protected classifications under
                    California or federal law
                  </strong>
                </td>
                <td>
                  Race, religion, sexual orientation, gender identity, gender
                  expression, age
                </td>
                <td>No</td>
              </tr>
              <tr>
                <td>
                  <strong className="opacity-90">Commercial information</strong>
                </td>
                <td className="py-8" s>
                  Records of personal property, products or services purchased,
                  obtained, or considered, or other purchasing or consuming
                  histories or tendencies
                </td>
                <td>No</td>
              </tr>
              <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
                <td>
                  <strong className="opacity-90">
                    Internet or other electronic network activity information
                  </strong>
                </td>
                <td>
                  Browsing history, search history, and information regarding a
                  consumer's interaction with an Internet website, application,
                  or advertisement
                </td>
                <td>Yes</td>
              </tr>
              <tr>
                <td>
                  <strong className="opacity-90">Geolocation data</strong>
                </td>
                <td className="py-8">Physical location</td>
                <td>Yes</td>
              </tr>
              <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
                <td>
                  <strong className="opacity-90">
                    Professional or employment-related information
                  </strong>
                </td>
                <td>Current or past job history</td>
                <td>No</td>
              </tr>
              <tr>
                <td>
                  <strong className="opacity-90">Inferences</strong>
                </td>
                <td className="py-8">
                  Profile reflecting a person's preferences, characteristics,
                  aptitudes
                </td>
                <td>Yes</td>
              </tr>
            </tbody>
          </table>
          <br />
          The sources from which we collect personal information are described
          in sections II. How We Collect Personal Information and III. Other
          Ways Personal Information is Collected;
          <br />
          the business or commercial purposes for which we use this information
          are described in sections IV. How We Use Your Personal Information and
          VI. Technologies We Use to Automatically Collect Personal Information;
          and
          <br />
          the categories of third parties to whom we may disclose this
          information are described in section V. Who We Share Your Personal
          Information With and Why of this Privacy Notice.
          <br />
          <br />
          <strong className="opacity-90">b. Nevada</strong>
          <br />
          Nevada law (NRS 603A.340) requires each business to establish a
          designated request address where Nevada consumers may submit requests
          directing the business not to sell certain kinds of personal
          information that the business has collected or will collect about the
          consumer. A sale under Nevada law is the exchange of personal
          information for monetary consideration by the business to a third
          party for the third party to license or sell the personal information
          to other third parties. If you are a Nevada consumer and wish to
          submit a request relating to our compliance with Nevada law, please
          contact us at{" "}
          <strong className="opacity-90">info@topgunautomobiles.com</strong>.
        </div>

        <div id="eighth" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold mb-3">
            VIII. Your Privacy Rights and Choices
          </h1>

          <p className="my-3">
            You have certain rights to the personal information that we process
            about you as part of the services we provide. Below is a detailed
            description of those rights, including additional state-specific
            rights.
            <br /> <strong>Verification :-</strong>
            <br />
            Consistent with applicable law, please understand that, depending on
            the type of request you submit, and to protect the confidentiality
            of yours and others' personal information, we will only complete
            your request when your identity has been verified. We will seek to
            match the information in your request to the personal information we
            maintain about you. As part of our verification process, we may ask
            you to: submit additional information, use identity verification
            services to assist, or, if you have set up an account on our
            website, to sign into your account as part of our identity
            verification process.
            <br /> <strong>Right to an Authorized Agent</strong>
            <br /> In certain states, consumers may designate an authorized
            agent to exercise their privacy rights. You may designate an
            authorized agent to submit requests on your behalf. However, we may
            require written proof of the agent's permission to do so and verify
            your identity directly.
            <br /> <strong> Right to Non-Discrimination</strong>
            <br /> You also have the right to not receive retaliatory or
            discriminatory treatment in connection with a request to exercise
            your rights. However, the exercising of the rights described below
            may result in a different price, rate, or quality/level of product
            or service where that difference is reasonably related to the impact
            the right has on our relationship with you or is otherwise permitted
            by law.
          </p>
          <table border="1">
            <tr className="bg-gray-200 border-t border-t-gray-400">
              <th>
                <strong>Access/Right to Know</strong>
              </th>
              <td>
                Consistent with applicable law, you may have the right to
                confirm in a portable and (if technically feasible) readily
                usable form, after making a verifiable request whether we are
                processing your personal information and, in some cases, to
                obtain certain personalized details about the personal
                information we have collected about you, including:
              </td>
            </tr>
            <tr className="bg-gray-200">
              <th></th>
              <td>1: The categories of sources of the personal information;</td>
            </tr>
            <tr className="bg-gray-200">
              <th></th>
              <td>2: The categories of personal information collected;</td>
            </tr>
            <tr className="bg-gray-200">
              <th></th>
              <td>
                3: The purposes for which the personal information were
                collected;
              </td>
            </tr>
            <tr className="bg-gray-200">
              <th></th>
              <td>
                4: The categories of personal information disclosed to third
                parties (if any), and the categories of recipients to whom the
                personal information were disclosed;
              </td>
            </tr>
            <tr className="bg-gray-200">
              <th></th>
              <td>
                5: The categories of personal information shared for targeted
                advertising or CCBA purposes (if any), and the categories of
                recipients to whom the data were disclosed for those purposes;
              </td>
            </tr>
            <tr className="bg-gray-200 border-b border-b-gray-400">
              <th></th>
              <td>
                6: The categories of personal information sold (if any), and the
                categories of third parties to whom the data were sold.
              </td>
            </tr>
            <tr>
              <th className="py-8">
                <strong>Correction</strong>
              </th>
              <td>
                Consistent with applicable law, you may have the right after
                making a verifiable request to correct inaccurate personal
                information, considering the nature of the data itself and the
                processing activities it supports.
              </td>
            </tr>
            <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
              <th>
                <strong>Deletion</strong>
              </th>
              <td>
                Consistent with applicable law, you may have the right, after
                making a verifiable request to have your personal information
                that is maintained by Top Gun Automobiles deleted.
              </td>
            </tr>
            <tr>
              <th>
                <strong>Opt-Out Rights</strong>
              </th>
              <td className="py-8">
                The right to direct Top Gun Automobiles to not “sell” your personal
                information for monetary or other valuable consideration, or
                “share” your personal information for targeted advertising or
                Cross-Context Behavioral Advertising (“CCBA”) purposes. You may
                opt out of such processing by navigating to "Your Privacy
                Choices" located in the footer of our site, or by clicking Your
                Privacy Choices.
              </td>
            </tr>
            <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
              <th>FOR CALIFORNIA RESIDENTS </th>
            </tr>
            <tr>
              <th>“Shine the Light”</th>
              <td className="py-8">
                California residents that have an established business
                relationship with us have the right to know how their personal
                information is disclosed to third parties for their direct
                marketing purposes under California's “Shine the Light” law, or
                the right to opt out of such practices (Civ. Code § 1798.83).
              </td>
            </tr>
            <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
              <th>
                Limit Use and/or Disclosure of Sensitive Personal Information
              </th>
              <td>
                Top Gun Automobiles does not use or disclose your sensitive personal
                information for purposes to which the right to limit use or
                disclosure applies.
              </td>
            </tr>
            <tr className="py-5">
              <th className="py-8">
                FOR COLORADO, CONNECTICUT OR VIRGINIA RESIDENTS
              </th>
            </tr>
            <tr className="bg-gray-200 border-t border-t-gray-400 border-b border-b-gray-400">
              <th>
                Right to Opt Out for the Purposes of Profiling for Decisions
                Producing Legal or Similarly Significant Effect
              </th>
              <td>
                Top Gun Automobiles does not create consumer profiles either for purposes of
                targeted advertising or for decision-making purposes that
                produce legal or similarly significant effects. We may, however,
                share your personal information for targeted advertising
                consistent with applicable law and this privacy notice. You may
                opt out of such processing by navigating to "Your Privacy
                Choices" located in the footer of our site, or by clicking Your
                Privacy Choices.
              </td>
            </tr>
            <tr>
              <th className="py-8">Right to Appeal Privacy Rights Request</th>
              <td>
                To appeal a refusal to take action on your request, please see
                the instructions in our response to your request or submit your
                appeal in writing by contacting info@topgunautomobiles.com with
                the subject “Appeal of Consumer Privacy Rights Request.”
              </td>
            </tr>
          </table>
          <p className="mt-4">
            b. <strong>Choices</strong>
            <br /> To exercise your right to Know, Delete, or Correct rights,
            please submit a request by visiting our My Privacy Rights page. To
            exercise your Opt Out rights, please navigate to "Your Privacy
            Choices" located in the footer of our site, or by clicking Your
            Privacy Choices. Or contact us with questions or requests regarding
            this Privacy Notice at:
            <br /> <strong> Address: </strong>
            <br /> Top Gun Automobiles  , Inc.
            <br /> 12180 Garland Rd, Dallas, TX 75218 ,
            <br /> MA 02115 Attention: <br /> Privacy info@topgunautomobiles.com{" "}
            <br /> You may also opt out of any or all future marketing emails
            from us here or by clicking on the unsubscribe link we place at the
            footer of every email we send that is not in response to an action
            taken by the user. Please note that you cannot opt out of
            non-promotional emails, such as those about your account,
            transactions or servicing. To opt out of marketing messages from car
            dealers with whom you’ve shared your personal information, please
            contact the individual dealer. <br />{" "}
            <strong>Targeted Advertising</strong> <br /> Also referred to as
            “interest based,” “online behavioral,” “personalized” advertising,
            as well as “Cross-Context Behavioral Advertising,” targeted
            advertising is the serving of digital advertisements based on
            predictions generated over time from your visits across different
            websites, devices, mobile applications, as well as our own. We
            encourage you to consult our detailed policy on Interest Based
            Advertising here.
            <br /> We may work with advertising technology partners who allow us
            to personalize our ads based on your browsing behavior on our
            digital properties. Many of these companies are participants of the
            Digital Advertising Alliance (“DAA”) and/or members of the Network
            Advertising Initiative (“NAI”). In addition to your right under your
            state's privacy law to opt out from targeted advertising by us and
            our own advertising partners, you can learn more about targeted ads
            provided by these companies as well as others we are not partnered
            with, and how to opt out of receiving certain targeted ads from them
            by visiting:
            <br /> https://www.aboutads.info/choices - For targeted ads from DAA
            participants on websites;
            <br />
            https://optout.networkadvertising.org/?c=1 - For targeted ads from
            NAI members on websites and to learn more about how to opt out from
            receiving targeted ads on your other connected devices.
            <br /> Opting out only means that the selected participants should
            no longer deliver certain targeted ads to the specific browser or
            device on which you made your request, it does not mean you will no
            longer receive any targeted content and/or ads from other
            advertising technology companies or on your other browsers or
            devices that have not been opted out. We also partner with Amazon to
            deliver advertisements. You can opt out of delivery of targeted
            advertising to you by Amazon here. Please note that even if you opt
            out, you will continue to receive advertisements, but they will not
            be tailored to your specific interests. We may also display
            interest-based ads to you when you are using Facebook and other
            social media platforms or websites.
          </p>
        </div>

        <div id="ninth" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold mb-3">
            IX. Publicly Posted Information
          </h1>
          <p className="mt-3">
            Your member profile will be publicly viewable and identifiable via
            your username. Top Gun Automobiles will not share your email address with any
            other members or display it publicly. Please consider carefully
            before making any information public as you are solely responsible
            for any information you make public. Once you have posted
            information, you may not be able to edit or delete it.
            <br /> If you choose to participate in our member-to-member
            communications programs, you may be contacted by other members,
            using us as an intermediary. All members have the option to turn off
            the member-to-member communication features at any time.
            <br /> Information that you make available to us and others via
            social media networks, forums, blogs, list serves, chat rooms or
            similar functionality is public information that we or others may
            share or use in accordance with the law.
          </p>
        </div>

        <div id="tenth" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold mb-3">
            X. Storing and Retaining Your Personal Information
          </h1>
          <p className="mt-3">
            Your personal information is stored on servers in the U.S. Also,
            some of our service providers may store information in servers
            hosted in countries different from where you reside. As such, your
            personal information may be subject to the laws of other countries,
            where the data protection and other laws may not be as comprehensive
            as your country of residence.
            <br /> Consistent with our recordkeeping policies and practices, we
            may retain your personal information so long as it is necessary to
            fulfill the purposes outlined in this Privacy Notice, unless a
            longer retention period is required by law. Once we no longer have a
            legitimate business reason to retain your personal information, it
            is either destroyed, aggregated or deidentified, however we may
            retain inactive archival copies consistent with applicable law.
          </p>
        </div>

        <div id="eleventh" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold mb-3">
            XI. Security - Steps we take to protect your personal information
          </h1>
          <p className="mt-3">
            We have implemented administrative, technical, personnel, and
            physical security measures designed to protect the personal
            information stored in our systems against loss, theft and
            unauthorized use, disclosure or modification. We also employ
            processes (such as password hashing, login auditing, and idle
            session termination, as appropriate) designed to protect against
            unauthorized access to your personal information. While we endeavor
            to create secure and reliable digital properties for users, the
            confidentiality of any communication or material transmitted to/from
            a Top Gun Automobiles digital property, and the security of your personal
            information, cannot be guaranteed. We encourage you to take steps to
            protect your personal information online.
          </p>
        </div>

        <div id="twelve" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold mb-3">
            XII. International Transfer
          </h1>
          <p>
            We are based in the U.S. and the information we collect is governed
            by U.S. law. If you are accessing our digital properties from
            outside of the U.S., please be aware that information collected may
            be transferred to, processed, stored, and used in the U.S. and other
            jurisdictions. Data protection laws in the U.S. and other
            jurisdictions may be different from those of your country of
            residence. Your use of this digital property or provision of any
            information therefore constitutes your consent to the transfer to
            and from, processing, usage, sharing, and storage of information
            about you in the U.S. and other jurisdictions as set out in this
            Privacy Notice.
          </p>
        </div>

        <div id="thirteen" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold mb-3">
            XIII. Children
          </h1>
          <p>
            Top Gun Automobiles digital properties are intended for a general audience and
            are not directed at children under (13) years of age.
            <br /> We do not knowingly gather personal information (as defined
            by the U.S. Children’s Online Privacy Protection Act, or “COPPA”) in
            a manner not permitted by COPPA. If you are a parent or guardian and
            you believe we have collected information from your child in a
            manner not permitted by law, contact us at
            info@topgunautomobiles.com. We will remove the data to the extent
            required by applicable laws.
            <br /> We do not knowingly “sell” the personal information of minors
            under 16 years old who are California residents without their
            affirmative authorization.
            <br /> If you are a California resident under 18 years old and
            registered to use a Top Gun Automobiles digital property, you can ask us to
            remove any content or information you have posted. To make a
            request, email us at the email address set out in “Contact Us”
            section with “California Under 18 Content Removal Request” in the
            subject line, and tell us what you want removed. We will make
            reasonable good faith efforts to remove the post from prospective
            public view, although we cannot ensure the complete or comprehensive
            removal of the content and may retain the content as necessary to
            comply with our legal obligations, resolve disputes, and enforce our
            agreements.
          </p>
        </div>

        <div id="fourthteen" className="mt-4">
          <h1 className="text-2xl opacity-90 font-semibold mb-3">
            XIV. Changes to this Privacy Notice
          </h1>
          <p className="mt-3">
            We may occasionally update this Privacy Notice to reflect changes in
            our practices. When we post modifications to this Privacy Notice, we
            will revise the "Last Updated" date at the top of this page. The
            modified Privacy Notice will be effective immediately upon posting.
            Your continued use of our digital properties after the posting of
            the modified Privacy Notice constitutes your agreement to abide and
            be bound by it. We encourage you to periodically review this page
            for the latest information on our privacy practices. If you object
            to any modification, your sole recourse is to stop using the digital
            property.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyStatement;
