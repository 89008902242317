import { BsCheck, BsX } from "react-icons/bs";

export default function VehicleFeature({ icon, title, check }) {
  return (
    <div className="grid items-center grid-cols-4">
      <img src={icon} alt="..." className="w-7 h-7" />
      <p className="col-span-2 text-xs">{title}</p>
      {check ? (
        <BsCheck size={22} className="text-green-600" />
      ) : (
        <BsX size={22} className="text-red-600" />
      )}
    </div>
  );
}
