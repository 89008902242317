
import React, { useState } from 'react';
import { CarSelection } from '../car-selection';
import { WarningModal } from '../modals/warning.modal';
import { SuccessModal } from '../modals/success.modal';
import FinancingForm from '../FinancingForm';

const Finance = () => {
    const [selectedCar, setSelectedCar] = useState(null)
    const [openSuccess, setOpenSuccess] = useState(false)
    const [openError, setOpenError] = useState(false)

    return (
        <div className='bg-gray-100'>
            <div className="container py-6">
                <div className='w-full lg:max-w-3xl mx-auto p-6 rounded-3xl shadow-sm bg-white'>
                    <div className='mb-6'>
                        <p className='text-lg font-semibold uppercase text-center tracking-wider'>Get financing for your car</p>
                    </div>

                    {/* Car selection container */}
                    <CarSelection onSelected={setSelectedCar} />

                    {/* finance form preview */}
                    {selectedCar ?
                        <div className='mt-4'>
                            <FinancingForm
                                vehicleId={selectedCar.id}
                                paymentMethod={"financing"}
                                onSuccess={() => {
                                    setOpenSuccess(true)
                                    setSelectedCar(null)
                                }}
                                onError={() => {
                                    setOpenError(true)
                                    setSelectedCar(null)
                                }}
                            />
                        </div>
                        : null
                    }

                    {/* Preview error dialog */}
                    <WarningModal open={openError} onHide={() => setOpenError(false)} message={"Something went wrong. Please try again."} />

                    {/* Preview success dialog */}
                    <SuccessModal open={openSuccess} onHide={() => setOpenSuccess(false)} message={"Your request has been submitted. We will get back to you shortly."} />
                </div>
                {!selectedCar && <div className='h-[25vh]' />}
            </div>
        </div>
    );
};

export default Finance;

