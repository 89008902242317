import { Fragment, useState } from "react";
import TGLogo from "../assets/img/tg-logo.png";
import { FiMenu } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const navigateTo = (path) => {
    setShow(false);
    navigate(path);
  }

  const menu = (
    <Fragment>
      <p onClick={() => navigateTo("/search")} className="text-sm text-white cursor-pointer hover:text-secondary">
        SEARCH CARS
      </p>
      <p onClick={() => navigateTo("/404")} className="text-sm text-white cursor-pointer hover:text-secondary">
        SELL/TRADE
      </p>
      <p onClick={() => navigateTo("/finance")} className="text-sm text-white cursor-pointer hover:text-secondary">
        FINANCING
      </p>
    </Fragment>
  );

  return (
    <div>
      <div className="w-full p-6 bg-primary">
        <div className="items-center justify-between hidden space-x-6 md:flex">
          <div className="flex items-center space-x-6">
            <img src={TGLogo} onClick={() => navigateTo("/")} className="w-40 cursor-pointer" />
            {menu}
          </div>
          <div className="px-3 py-1 border rounded-lg cursor-pointer hover:bg-secondary">
            <p onClick={() => navigateTo("/contact")} className="text-sm text-white">
              Contact Us
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between md:hidden">
          <img src={TGLogo} onClick={() => navigateTo("/")} className="w-40 cursor-pointer" />
          {show ? (
            <AiOutlineClose
              onClick={() => setShow(false)}
              className="w-6 h-6 text-white cursor-pointer"
            />
          ) : (
            <FiMenu
              onClick={() => setShow(true)}
              className="w-6 h-6 text-white cursor-pointer"
            />
          )}
        </div>
        {show && <div className="grid gap-4 mt-6 ml-4 md:hidden">{menu}</div>}
      </div>
      <div className="h-2 text-xs text-white bg-secondary"></div>
    </div>
  );
}
