export const sendMessage = async (data) => {
  const response = await fetch(
    "https://posh-cloud-w7lwqlhjsa-uc.a.run.app/webhooks/topgun-contact-service",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );

  if (response.status === 200) {
    const data = await response.json();
    return { status: response.status, data };
  } else {
    const err = await response.text();
    throw new Error(err);
  }
};
