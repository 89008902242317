import { createContext, useEffect, useState } from "react";
import { getDocs, collection, getDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
// create context for data
export const DataContext = createContext({
  vehicles: [],
  info: {},
});

export const DataProvider = ({ children }) => {
  const [loadingData, setLoadingData] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [info, setInfo] = useState({});

  const fetchVehicles = async () => {
    setLoadingData(true);
    const response = await getDocs(collection(db, "Vehicles"));
    const data = response.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setVehicles(data);
    setLoadingData(false);
  };

  const fetchInfo = async () => {
    setLoadingData(true);
    const response = await getDoc(doc(db, "Settings", "settings"));
    setInfo(response.data());
    setLoadingData(false);
  };

  useEffect(() => {
    fetchVehicles();
    fetchInfo();
  }, []);

  return (
    <DataContext.Provider
      value={{
        loadingData,
        setLoadingData,
        vehicles,
        info,
        setInfo,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
