import { Fragment, useState } from 'react'
import { IoIosCheckmarkCircle } from "react-icons/io";

export const RadioInput = ({ items, onSelect }) => {
    const [selected, setSelected] = useState(null)

    return (
        <>
            {items && items.length ? items.map((item, i) =>
                <div key={`item-${i}`} className={`w-full p-3 rounded-xl cursor-pointer transition-all border border-slate-200 hover:bg-slate-100 mb-2 ${i === selected ? "bg-gray-100" : "bg-white"}`}
                    onClick={() => {
                        setSelected(i)
                        onSelect(item)
                    }}
                >
                    <div className="flex w-full items-center gap-3">
                        {item.image ?
                            <div className='flex-none !w-10 !h-10 rounded-md overflow-hidden'>
                                <img src={item.image} alt='...' className='min-w-full min-h-full' />
                            </div>
                            : null
                        }
                        <div className='grow'>
                            <p className="font-semibold text-sm capitalize text-black">{item.label || ""}</p>
                            <div className="flex flex-wrap md:flex-nowrap gap-2 text-xs text-gray-500">
                                {item.values && item.values.length ? item.values.map((value, k) =>
                                    <Fragment key={k}>
                                        <div>{value}</div>
                                        {k + 1 < item.values.length && <div className='w-1 h-1 mt-1.5 rounded-full bg-gray-500' />}
                                    </Fragment>
                                ) : null}
                            </div>
                        </div>

                        <IoIosCheckmarkCircle size={21} className={`fill-black ml-auto transition ${i === selected ? "opacity-100" : "opacity-0"}`} />
                    </div>
                </div>
            ) : null}
        </>
    )
}
