
export const VehicleCardPreloader = () => {
    return (
        <div className="w-full rounded-xl overflow-hidden bg-gray-100">
            <div className="p-4 md:min-h-[320px]">
                <div>
                    <div className="h-3 rounded-full w-28 mb-2 animate-pulse bg-slate-300" />
                    <div className="h-4 rounded-full w-40 mb-2 animate-pulse bg-slate-300" />
                    <div className="h-2 rounded-full w-24 animate-pulse bg-slate-300" />
                </div>
                <div className="w-full h-[230px] md:h-[200px] lg:h-[180px] mx-auto rounded-xl my-2 animate-pulse bg-slate-300" />
                <hr />
                <div className="rounded-full h-5 mt-4 w-28 mx-auto animate-pulse bg-slate-300" />
            </div>
        </div>
    )
}