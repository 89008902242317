import { useCallback, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RadioGroup, FormControlLabel, Radio, Button } from "@mui/material";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";

export default function BasicAccordion({ onApply, onReset, isFinancing }) {
  const [priceSort, setPriceSort] = useState("");
  const [mileageSort, setMileageSort] = useState("");
  const [yearSort, setYearSort] = useState("");
  const [brandSort, setBrandSort] = useState("");
  const [bodySort, setBodySort] = useState("");
  const [brands, setBrands] = useState([]);
  const [bodies, setBodies] = useState([]);

  // fetch vehicles from firestore and make a set of unique brands and body types
  const fetchVehicles = useCallback(async () => {
    try {
      const response = await getDocs(collection(db, "Vehicles"));
      const brandsData = new Set();
      const bodyData = new Set();

      response.forEach(doc => {
        const data = doc.data();
        if (data.make) {
          brandsData.add(data.make);
        }

        if (data.body) {
          bodyData.add(data.body);
        }
      });

      const uniqueBrands = Array.from(brandsData);
      const uniqueBodies = Array.from(bodyData);
      setBrands(uniqueBrands);
      setBodies(uniqueBodies);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchVehicles();
  }, [fetchVehicles]);

  const handleApply = () => {
    const filters = {
      price: priceSort,
      mileage: mileageSort,
      year: yearSort,
      brand: brandSort,
      body: bodySort,
    };
    onApply(filters);

  };

  const handleReset = () => {
    setPriceSort("");
    setMileageSort("");
    setYearSort("");
    setBrandSort("");
    setBodySort("");

    onApply({
      price: "",
      mileage: "",
      year: "",
      brand: "",
      body: "",
    });

    onReset();
  };

  const getFilterLabel = (filterValue, type) => {
    if (!filterValue) return "";

    switch (type) {
      case "price":
        return filterValue === "priceLowToHigh" ? "Low to High" : "High to Low";
      case "mileage":
        return filterValue === "mileageLowToHigh" ? "Low to High" : "High to Low";
      case "year":
        return filterValue === "yearOldToNew" ? "Old to New" : "New to Old";
      default:
        return "";
    }
  };

  return (
    <div>
      {/* Price Accordion */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <p>Price {getFilterLabel(priceSort, "price") && `(${getFilterLabel(priceSort, "price")})`}</p>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            row
            aria-label="price"
            name="price"
            value={priceSort}
            onChange={(e) => setPriceSort(e.target.value)}
          >
            <FormControlLabel
              value="priceLowToHigh"
              control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
              label="Low to High"
            />
            <FormControlLabel
              value="priceHighToLow"
              control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
              label="High to Low"
            />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>

      {/* Mileage Accordion */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <p>Mileage {getFilterLabel(mileageSort, "mileage") && `(${getFilterLabel(mileageSort, "mileage")})`}</p>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            row
            aria-label="mileage"
            name="mileage"
            value={mileageSort}
            onChange={(e) => setMileageSort(e.target.value)}
          >
            <FormControlLabel
              value="mileageLowToHigh"
              control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
              label="Low to High"
            />
            <FormControlLabel
              value="mileageHighToLow"
              control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
              label="High to Low"
            />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>

      {/* Year Accordion */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <p>Year {getFilterLabel(yearSort, "year") && `(${getFilterLabel(yearSort, "year")})`}</p>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            row
            aria-label="year"
            name="year"
            value={yearSort}
            onChange={(e) => setYearSort(e.target.value)}
          >
            <FormControlLabel
              value="yearOldToNew"
              control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
              label="Old to New"
            />
            <FormControlLabel
              value="yearNewToOld"
              control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
              label="New to Old"
            />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>

      {/* Preview available only for financing page */}
      {isFinancing ?
        <>
          {/* Brand Accordion */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4a-header"
            >
              <p>Brand</p>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                row
                name="brand"
                aria-label="brand"
                value={brandSort}
                onChange={(event) => setBrandSort(event.target.value)}
              >
                {brands.map((brand) => (
                  <FormControlLabel
                    key={brand}
                    value={brand}
                    control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
                    label={brand}
                  />
                ))}
              </RadioGroup>
            </AccordionDetails>
          </Accordion>

          {/* Body Accordion */}
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <p>Body Style</p>
            </AccordionSummary>
            <AccordionDetails>
              <RadioGroup
                row
                name="body"
                aria-label="body"
                value={bodySort}
                onChange={(event) => setBodySort(event.target.value)}
              >
                {bodies.map((body) => (
                  <FormControlLabel
                    key={body}
                    value={body}
                    control={<Radio sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }} />}
                    label={body}
                  />
                ))}
              </RadioGroup>
            </AccordionDetails>
          </Accordion>
        </>
        : null}

      {/* Apply and Reset buttons */}
      <div className="flex justify-end space-x-2 mt-4">
        <Button variant="outlined" color="secondary" size="small" onClick={handleReset}>
          Reset
        </Button>
        <Button variant="contained" color="primary" size="small" onClick={handleApply}>
          Apply
        </Button>
      </div>
    </div>
  );
}
