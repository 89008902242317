import { Button } from "@mui/material";
import NotFoundImage from "../../assets/img/404-image.jpg";
import { useEffect } from "react";

export default function NotFound() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div className="p-8 bg-gray-200">
      <div className="grid gap-4 p-8 bg-white rounded-lg justify-items-center">
        <p className="text-2xl font-bold text-primary">Page Not Found!</p>
        <p className="text-sm font-light text-gray-700">
          This page is either being developed or not available.
        </p>
        <Button variant="contained" color="primary">
          Go Home
        </Button>
        <img src={NotFoundImage} className="rounded-lg" />
      </div>
    </div>
  );
}
