export default function Sell() {
    return (
        <div className="grid content-start min-h-screen gap-4 px-4 py-12 bg-gray-200 md:px-8 md:justify-items-center">
            <div className="p-4 border-t-8 page md:w-3/4 border-secondary">
                <p className="text-2xl font-bold text-primary">Sell or Trade your Vehicle</p>
            </div>

            <div className="page">

            </div>
        </div>
    )
}