import { Fragment } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { IoCheckmarkDone } from "react-icons/io5";
import { Dialog, Transition } from '@headlessui/react';

export const SuccessModal = ({ open, onHide, message }) => {
    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={onHide}>
                {/* Overlay */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-60" aria-hidden="true" />
                </Transition.Child>

                {/* Panel */}
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="relative grid p-8 w-full md:max-w-lg lg:max-w-2xl rounded-xl bg-white">
                            <AiOutlineClose
                                size={24}
                                onClick={() => onHide()}
                                className="absolute top-5 right-5 text-primary cursor-pointer"
                            />
                            <div className='text-center pt-8 pb-4'>
                                <IoCheckmarkDone size={50} className="mx-auto mb-2 text-orange-600" />
                                <p className="text-2xl font-bold text-primary">Successful</p>
                                <p className="text-sm font-light text-gray-600">
                                    {message}
                                </p>
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

