import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Toyota from "../assets/img/logo/toyota.png";
import Mercedes from "../assets/img/logo/mercedes.png";
import Tesla from "../assets/img/logo/tesla.png";
import BMW from "../assets/img/logo/bmw.png";
import Audi from "../assets/img/logo/audi.png";
import LandRover from "../assets/img/logo/land-rover.png";
import Porsche from "../assets/img/logo/porsche.png";
import KIA from "../assets/img/logo/kia-new.png";

const data = [
     { title: "Mercedes", image: Mercedes },
     { title: "BMW", image: BMW },
     { title: "Tesla", image: Tesla },
     { title: "Toyota", image: Toyota },
     { title: "Audi", image: Audi },
     { title: "LandRover", image: LandRover },
     { title: "Porsche", image: Porsche },
     { title: "KIA", image: KIA },
]


export default function CarouselBrand() {
     const navigate = useNavigate();

     // handle navigate to search page
     const handleBrandClick = (bodyStyle) => navigate(`/search/brand/${bodyStyle}`);

     return (
          <Fragment>
               <p className="text-2xl font-bold text-center text-primary">
                    Shop by Brand
               </p>
               <div
                    className="flex items-center md:justify-center w-4/5 space-x-8 overflow-scroll md:space-x-16 scrollbar-hide">
                    {data.map((item, index) => (
                         <img
                              src={item.image}
                              alt="..."
                              className="h-24 p-2 border rounded-lg cursor-pointer hover:bg-stone-200"
                              onClick={() => handleBrandClick(item.title)}
                              key={index}
                         />
                    ))}


               </div>
          </Fragment>
     );
}
