

import { Fragment, useState } from "react";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@mui/material/MobileStepper";
import Button from "@mui/material/Button";

function ImageCarousel({ images }) {
  const theme = useTheme();
  const maxSteps = images.length;
  const [activeStep, setActiveStep] = useState(0);

  // Handle go to next slide 
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // Handle go to previous slide
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // Handle step change
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Fragment>
      <div className="relative rounded-2xl overflow-hidden">
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((img, index) => (
            <div
              key={index}
              className={`w-full h-auto rounded-2xl overflow-hidden`}
            >
              <img src={img} alt="..." className="object-cover" />
            </div>
          ))}
        </SwipeableViews>



        {/* Centered navigation buttons on the image */}
        <div className="absolute top-1/2 transform -translate-y-1/2 w-full flex justify-between px-4">
          <button
            size="large"
            onClick={handleBack}
            disabled={activeStep === 0}
            className="bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
          </button>

          <button
            size="large"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            className="bg-white bg-opacity-50 hover:bg-opacity-75 rounded-full p-2"
          >
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </button>
        </div>
      </div>

      {/* Centered steps indicator */}
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{ padding: "20px 0px" }}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />

      {/* Thumbnails */}
      <div className="flex space-x-2 overflow-scroll w-fit h-[90px] scrollbar-hide">
        {images.map((img, index) => (
          <img
            alt="..."
            onClick={() => handleStepChange(index)}
            key={index}
            src={img}
            className="w-32 rounded-lg cursor-pointer hover:opacity-75 hover:border"
          />
        ))}
      </div>
    </Fragment>
  );
}

export default ImageCarousel;
