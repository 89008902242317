import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
    TextField,
    Button,
    Box,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    FormGroup,
    FormControlLabel, Checkbox, TableHead, TableRow, TableCell, TableBody, TableContainer, Table, Paper
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Typography from "@mui/material/Typography";
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import * as emailjs from "emailjs-com";
import styles from './financingForm.module.css'

export default function FinancingForm({ vehicleId, paymentMethod, onSuccess, onError }) {
    const email_service_id = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const email_public_key = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
    const email_template_id = process.env.REACT_APP_FINANCE_EMAIL_TEMPLATE_ID;

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        address: '',
        dob: null,
        streetAddress: "",
        aptUnit: "",
        city: "",
        state: "",
        zipCode: "",
        monthlyPayment: "",
        jobTitle: "",
        income: "",
        liveDuration: "",
        ownRent: "",
        workDuration: "",
        jobType: "",
        creditScore: "",
        agreeToTerms: false,
        jointApplication: false,
        coApplicantFirstName: '',
        coApplicantLastName: '',
        coApplicantEmail: '',
        coApplicantPhone: '',
        coApplicantAddress: '',
        paymentMethod: paymentMethod,
        vehicleId: vehicleId
    });

    const usStates = [
        "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
        "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho",
        "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana",
        "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi",
        "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey",
        "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio",
        "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia",
        "Washington", "West Virginia", "Wisconsin", "Wyoming"
    ];

    const panels = ['personal-info', 'residence-info', 'employment-info', 'estimated-credit-score-info', 'review-application', 'co-applicant-info'];
    const [expanded, setExpanded] = useState('personal-info');
    const [formErrors, setFormErrors] = useState({ dob: '' });
    const [isReviewing, setIsReviewing] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isJointApplication, setIsJointApplication] = useState(false);

    const handleJointApplicationChange = (event) => {
        setIsJointApplication(event.target.value === "Yes");
        formData.jointApplication = event.target.value === "Yes";
    };


    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const handleNextAccordion = (nextPanel) => {
        if (validate([expanded])) {
            setExpanded(nextPanel);
        }
    };

    const handlePrevAccordion = (prevPanel) => {
        setExpanded(prevPanel);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCheckboxInputChange = (event) => {
        const { name, checked, value, type } = event.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleDateChange = (newValue) => {
        setFormData({
            ...formData,
            dob: newValue,
        });

        if (!newValue) {
            setFormErrors({
                ...formErrors,
                dob: 'Date of birth is required',
            });
        } else {
            setFormErrors({
                ...formErrors,
                dob: '',
            });
        }
    }

    const validate = (panel: []) => {
        let errors = {};
        let errorPanel = "";

        if (panel.includes('personal-info')) {
            if (!formData.firstname.trim()) errors.firstname = "First Name is required";
            if (!formData.lastname.trim()) errors.lastname = "Last Name is required";
            if (!formData.email) errors.email = "Email is required";
            else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) errors.email = "Invalid email address";
            if (!formData.phone) errors.phone = "Phone number is required";
            else if (!/^\+1[2-9]\d{2}[2-9](?!11)\d{6}$/.test(formData.phone)) errors.phone = "Phone number must be a valid US number with country code (e.g., +12345678901)";
            if (!formData.dob) {
                errors.dob = "Date of Birth is required";
            }
            if (Object.keys(errors).length !== 0 && !errorPanel) {
                errorPanel = "personal-info";
            }
        }

        if (panel.includes('residence-info')) {
            if (!formData.streetAddress.trim()) errors.streetAddress = "Street Address is required";
            if (!formData.city.trim()) errors.city = "City is required";
            if (!formData.state) errors.state = "State is required";
            if (!formData.liveDuration) errors.liveDuration = "Duration is required";
            if (!formData.ownRent) errors.ownRent = "Rent is required";
            if (!formData.zipCode.trim()) errors.zipCode = "Zip Code is required";
            if (!formData.monthlyPayment.trim()) errors.monthlyPayment = "Monthly Payment is required";
            if (Object.keys(errors).length !== 0 && !errorPanel) {
                errorPanel = "residence-info";
            }
        }

        if (panel.includes('employment-info')) {
            if (!formData.jobType) errors.jobType = "Job Type is required";
            if (!formData.jobTitle.trim()) errors.jobTitle = "Job Title is required";
            if (!formData.workDuration) errors.workDuration = "Work Duration is required";
            if (!formData.income.trim()) errors.income = "Income is required";
            if (Object.keys(errors).length !== 0 && !errorPanel) {
                errorPanel = "employment-info";
            }
        }

        if (panel.includes('estimated-credit-score-info')) {
            if (!formData.creditScore) errors.creditScore = "Estimated Credit Score is required";
            if (Object.keys(errors).length !== 0 && !errorPanel) {
                errorPanel = "estimated-credit-score-info";
            }
        }

        if (panel.includes('co-applicant-info')) {
            if (isJointApplication) {
                if (!formData.coApplicantFirstName.trim()) errors.coApplicantFirstName = "Co-Applicant First Name is required";
                if (!formData.coApplicantLastName.trim()) errors.coApplicantLastName = "Co-Applicant Last Name is required";
                if (!formData.coApplicantEmail) errors.coApplicantEmail = "Co-Applicant Email is required";
                else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.coApplicantEmail)) errors.coApplicantEmail = "Invalid Co-Applicant Email address";
                if (!formData.coApplicantPhone) errors.coApplicantPhone = "Co-Applicant Phone number is required";
                else if (!/^\+1[2-9]\d{2}[2-9](?!11)\d{6}$/.test(formData.coApplicantPhone)) errors.coApplicantPhone = "Co-Applicant Phone number must be a valid US number with country code (e.g., +12345678901)";
                if (!formData.coApplicantAddress.trim()) errors.coApplicantAddress = "Co-Applicant Address is required";
            }
            if (Object.keys(errors).length !== 0 && !errorPanel) {
                errorPanel = "co-applicant-info";
            }
        }

        if (panel.includes('review-application')) {
            if (formData.agreeToTerms === false) errors.agreeToTerms = "Please check the terms and conditions.";
            if (Object.keys(errors).length !== 0 && !errorPanel) {
                errorPanel = "review-application";
            }
        }

        setExpanded(errorPanel);
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleReview = () => {
        if (!validate(panels)) {
            setIsSubmitting(false);
            return;
        }
        setIsReviewing(true);
    };

    const handleEdit = () => {
        setIsReviewing(false);
    };

    const sendEmail = async (formData, vehicle) => {
        const templateParams = {
            firstname: formData.firstname,
            lastname: formData.lastname,
            email: formData.email,
            phone: formData.phone,
            dob: formData.dob ? new Date(formData.dob).toLocaleDateString('en-US') : '',
            streetAddress: formData.streetAddress,
            aptUnit: formData.aptUnit,
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode,
            liveDuration: formData.liveDuration,
            ownRent: formData.ownRent,
            monthlyPayment: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(formData.monthlyPayment),
            jobType: formData.jobType,
            jobTitle: formData.jobTitle,
            workDuration: formData.workDuration,
            income: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(formData.income),
            creditScore: formData.creditScore,
            paymentMethod: formData.paymentMethod,
            jointApplication: formData.jointApplication,
            coApplicantFirstName: formData.coApplicantFirstName ? formData.coApplicantFirstName : 'N/A',
            coApplicantLastName: formData.coApplicantLastName ? formData.coApplicantLastName : 'N/A',
            coApplicantEmail: formData.coApplicantEmail ? formData.coApplicantEmail : 'N/A',
            coApplicantPhone: formData.coApplicantPhone ? formData.coApplicantPhone : 'N/A',
            coApplicantAddress: formData.coApplicantAddress ? formData.coApplicantAddress : 'N/A',
            body: vehicle.body,
            make: vehicle.make,
            trim: vehicle.trim,
            color: vehicle.color,
            model: vehicle.model,
            mileage: vehicle.mileage,
            vin: vehicle.vin,
            year: vehicle.year,
            price: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(vehicle.price),
            coApplicantEmailAsCC: formData.coApplicantEmail ? formData.coApplicantEmail : ""
        };

        return await emailjs.send(email_service_id, email_template_id, templateParams, email_public_key);
    };

    const fetchVehicle = async ({ vehicleId }) => {
        const data = await getDoc(doc(db, "Vehicles", vehicleId));
        return { id: data.id, ...data.data() };
    };

    const handleConfirmSubmit = async () => {
        setIsSubmitting(true);
        try {
            const addDocResponse = await addDoc(collection(db, "Buy"), {
                ...formData,
                dob: formData.dob ? formData.dob.toISOString().split('T')[0] : null
            })

            if (addDocResponse && formData.vehicleId) {
                const vehicleResponse = await fetchVehicle({ vehicleId: formData.vehicleId });
                const emailSendResponse = await sendEmail(formData, vehicleResponse);
                if (emailSendResponse && emailSendResponse.status === 200) {
                    setFormData({
                        firstname: '',
                        lastname: '',
                        email: '',
                        phone: '',
                        address: '',
                        dob: null,
                        streetAddress: "",
                        aptUnit: "",
                        city: "",
                        state: "",
                        zipCode: "",
                        monthlyPayment: "",
                        jobTitle: "",
                        income: "",
                        liveDuration: "",
                        ownRent: "",
                        workDuration: "",
                        jobType: "",
                        creditScore: "",
                        agreeToTerms: false,
                        jointApplication: false,
                        coApplicantFirstName: '',
                        coApplicantLastName: '',
                        coApplicantEmail: '',
                        coApplicantPhone: '',
                        coApplicantAddress: '',
                        paymentMethod: '',
                        vehicleId: ''
                    });
                    onSuccess();
                }
            }
        } catch (e) {
            console.error("Error adding document: ", e);
            onError();
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            {isReviewing ? (
                <Box>
                    <Typography variant="h6">Review Your Information</Typography>

                    <TableContainer component={Paper} sx={{ mt: 2 }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Section</strong></TableCell>
                                    <TableCell><strong>Field</strong></TableCell>
                                    <TableCell><strong>Value</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                <TableRow>
                                    <TableCell rowSpan={5}><strong>Personal Information</strong></TableCell>
                                    <TableCell><strong>First Name</strong></TableCell>
                                    <TableCell>{formData.firstname}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Last Name</strong></TableCell>
                                    <TableCell>{formData.lastname}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Email</strong></TableCell>
                                    <TableCell>{formData.email}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Phone</strong></TableCell>
                                    <TableCell>{formData.phone}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Date of Birth</strong></TableCell>
                                    <TableCell>{formData.dob ? formData.dob.format('MM/DD/YYYY') : ''}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell rowSpan={8}><strong>Residence Information</strong></TableCell>
                                    <TableCell><strong>Street Address</strong></TableCell>
                                    <TableCell>{formData.streetAddress}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Apt/Unit</strong></TableCell>
                                    <TableCell>{formData.aptUnit}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>City</strong></TableCell>
                                    <TableCell>{formData.city}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>State</strong></TableCell>
                                    <TableCell>{formData.state}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Zip Code</strong></TableCell>
                                    <TableCell>{formData.zipCode}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>How long have you lived here?</strong></TableCell>
                                    <TableCell>{formData.liveDuration} Year(s)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Own or Rent</strong></TableCell>
                                    <TableCell>{formData.ownRent}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Monthly Payment</strong></TableCell>
                                    <TableCell>{parseFloat(formData.monthlyPayment).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell rowSpan={4}><strong>Employment Information</strong></TableCell>
                                    <TableCell><strong>Job Type</strong></TableCell>
                                    <TableCell>{formData.jobType}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Job Title</strong></TableCell>
                                    <TableCell>{formData.jobTitle}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>How Long have you worked here?</strong></TableCell>
                                    <TableCell>{formData.workDuration} Year(s)</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>Primary Annual Income BEFORE taxes</strong></TableCell>
                                    <TableCell>{parseFloat(formData.income).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</TableCell>
                                </TableRow>

                                <TableRow>
                                    <TableCell rowSpan={1}><strong>Credit Information</strong></TableCell>
                                    <TableCell><strong>Estimated Credit Score</strong></TableCell>
                                    <TableCell>{formData.creditScore}</TableCell>
                                </TableRow>

                                {formData.jointApplication ? (
                                    <>
                                        <TableRow>
                                            <TableCell rowSpan={5}><strong>Co-Applicant Information</strong></TableCell>
                                            <TableCell><strong>Co-Applicant First Name</strong></TableCell>
                                            <TableCell>{formData.coApplicantFirstName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Co-Applicant Last Name</strong></TableCell>
                                            <TableCell>{formData.coApplicantLastName}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Co-Applicant Email</strong></TableCell>
                                            <TableCell>{formData.coApplicantEmail}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Co-Applicant Phone</strong></TableCell>
                                            <TableCell>{formData.coApplicantPhone}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell><strong>Co-Applicant Address</strong></TableCell>
                                            <TableCell>{formData.coApplicantAddress}</TableCell>
                                        </TableRow>
                                    </>
                                ) : (
                                    <TableRow>
                                        <TableCell rowSpan={1}><strong>Co-Applicant Information</strong></TableCell>
                                        <TableCell colSpan={3}><strong>No Joint Application</strong></TableCell>
                                    </TableRow>
                                )}

                                <TableRow>
                                    <TableCell rowSpan={1}><strong>Terms and Agreement</strong></TableCell>
                                    <TableCell><strong>Agreed to Terms</strong></TableCell>
                                    <TableCell>{formData.agreeToTerms ? 'Yes' : 'No'}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Box mt={2} display="flex" justifyContent="space-between">
                        <Button variant="contained" color="secondary" onClick={handleEdit}>
                            Edit
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleConfirmSubmit}
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Confirm & Submit'}
                        </Button>
                    </Box>
                </Box>
            ) : (
                <form className={styles.form} onSubmit={handleConfirmSubmit}>
                    <Accordion expanded={expanded === 'personal-info'}
                        onChange={handleAccordionChange('personal-info')}>
                        <AccordionSummary className='border-b-2' id='personal-info' expandIcon={<ExpandMore />} sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                            marginTop: 1,
                            marginBottom: expanded === 'personal-info' ? "10px" : "",
                            borderBottom: expanded === 'personal-info' ? "2px solid #eee" : ""
                        }}>
                            <h1 className='font-semibold font-sans' style={{ fontSize: '17px' }}>1: Personal Info</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <fieldset className={styles.customFieldset}>
                                <legend>First Name</legend>
                                <TextField
                                    name="firstname"
                                    value={formData.firstname || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.firstname}
                                    helperText={formErrors.firstname}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    className={styles.customTextfield}
                                    size='small'
                                />
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>Last Name</legend>
                                <TextField
                                    name="lastname"
                                    value={formData.lastname || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.lastname}
                                    helperText={formErrors.lastname}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>Email</legend>
                                <TextField
                                    name="email"
                                    value={formData.email || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.email}
                                    helperText={formErrors.email}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>Phone Number</legend>
                                <TextField
                                    name="phone"
                                    value={formData.phone || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.phone}
                                    helperText={formErrors.phone}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>

                            <fieldset className={styles.customFieldset}>
                                <legend>Date of Birth</legend>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        name='dob'
                                        value={formData.dob}
                                        onChange={handleDateChange}
                                        slotProps={{
                                            textField: {
                                                error: !!formErrors.dob,
                                                helperText: formErrors.dob,
                                                fullWidth: true,
                                                size: "small"
                                            }
                                        }}
                                        sx={{
                                            marginTop: 1,
                                            width: '100%',
                                            paddingBottom: '10px'
                                        }}
                                        className={styles.customTextfield}
                                    />
                                </LocalizationProvider>
                            </fieldset>

                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                <Button variant="contained" color="secondary"
                                    onClick={() => handleNextAccordion('residence-info')}>
                                    Next
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'residence-info'}
                        onChange={handleAccordionChange('residence-info')}>
                        <AccordionSummary id='residence-info' expandIcon={<ExpandMore />} sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                            marginTop: 1,
                            marginBottom: expanded === 'residence-info' ? "10px" : "",
                            borderBottom: expanded === 'residence-info' ? "2px solid #eee" : ""
                        }}>
                            <h1 className='text-xl font-semibold font-sans' style={{ fontSize: '17px' }}>2: Residence Info</h1>

                        </AccordionSummary>
                        <AccordionDetails>
                            <fieldset className={styles.customFieldset}>
                                <legend>Street Address</legend>
                                <TextField
                                    name="streetAddress"
                                    value={formData.streetAddress || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.streetAddress}
                                    helperText={formErrors.streetAddress}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>Apt/Unit (Optional)</legend>
                                <TextField
                                    name="aptUnit"
                                    value={formData.aptUnit || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.aptUnit}
                                    helperText={formErrors.aptUnit}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>City</legend>
                                <TextField
                                    name="city"
                                    value={formData.city || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.city}
                                    helperText={formErrors.city}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>State</legend>
                                <FormControl fullWidth error={!!formErrors.state} sx={{
                                    paddingBottom: "10px"
                                }}>
                                    <Select
                                        name="state"
                                        value={formData.state || ''}
                                        onChange={handleInputChange}
                                        sx={{
                                            marginTop: 1,
                                        }}
                                        size='small'
                                        className={styles.customTextfield}
                                    >
                                        {usStates.map((state) => (
                                            <MenuItem key={state} value={state}>
                                                {state}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formErrors.state && <FormHelperText>{formErrors.state}</FormHelperText>}
                                </FormControl>
                            </fieldset>

                            <fieldset className={styles.customFieldset}>
                                <legend>Zip Code</legend>
                                <TextField
                                    name="zipCode"
                                    value={formData.zipCode || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.zipCode}
                                    helperText={formErrors.zipCode}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>How long have you lived here?</legend>
                                <FormControl fullWidth error={!!formErrors.liveDuration} sx={{
                                    paddingBottom: "10px"
                                }}>
                                    <Select
                                        name="liveDuration"
                                        value={formData.liveDuration || ''}
                                        onChange={handleInputChange}
                                        sx={{
                                            marginTop: 1,
                                        }}
                                        size='small'
                                        className={styles.customTextfield}
                                    >
                                        {Array.from({ length: 100 }, (_, index) => (
                                            <MenuItem key={index + 1} value={index + 1}>
                                                {index + 1} {index + 1 === 1 ? 'Year' : 'Years'}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formErrors.liveDuration &&
                                        <FormHelperText>{formErrors.liveDuration}</FormHelperText>}
                                </FormControl>
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>Do you own or rent?</legend>
                                <FormControl fullWidth error={!!formErrors.ownRent} sx={{
                                    paddingBottom: "10px"
                                }}>
                                    <Select
                                        name="ownRent"
                                        value={formData.ownRent || ''}
                                        onChange={handleInputChange}
                                        sx={{
                                            marginTop: 1,
                                        }}
                                        size='small'
                                        className={styles.customTextfield}
                                    >
                                        <MenuItem value={"Own"}>Own</MenuItem>
                                        <MenuItem value={"Rent"}>Rent</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                    </Select>
                                    {formErrors.ownRent && <FormHelperText>{formErrors.ownRent}</FormHelperText>}
                                </FormControl>
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>Monthly Payment</legend>
                                <TextField
                                    name="monthlyPayment"
                                    value={formData.monthlyPayment || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.monthlyPayment}
                                    helperText={formErrors.monthlyPayment}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>

                            <Box display="flex" justifyContent="space-between" mt={2}>
                                <Button variant="contained" color="error"
                                    onClick={() => handlePrevAccordion('personal-info')}>
                                    Previous
                                </Button>

                                <Button variant="contained" color="secondary"
                                    onClick={() => handleNextAccordion('employment-info')}>
                                    Next
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'employment-info'}
                        onChange={handleAccordionChange('employment-info')}>
                        <AccordionSummary id="employment" expandIcon={<ExpandMore />} sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                            marginTop: 1,
                            marginBottom: expanded === 'employment-info' ? "10px" : "",
                            borderBottom: expanded === 'employment-info' ? "2px solid #eee" : ""
                        }}>

                            <h1 className='text-xl font-semibold font-sans' style={{ fontSize: '17px' }}>3: Employment Info</h1>
                        </AccordionSummary>
                        <AccordionDetails>

                            <fieldset className={styles.customFieldset}>
                                <legend>Job Type</legend>
                                <FormControl fullWidth error={!!formErrors.jobType} sx={{
                                    paddingBottom: "10px"
                                }}>
                                    <Select
                                        name="jobType"
                                        value={formData.jobType || ''}
                                        onChange={handleInputChange}
                                        sx={{
                                            marginTop: 1
                                        }}
                                        size='small'
                                        className={styles.customTextfield}
                                    >
                                        <MenuItem value={"Full Time"}>Full Time</MenuItem>
                                        <MenuItem value={"Part-time"}>Part-time</MenuItem>
                                        <MenuItem value={"Self-employed"}>Self-employed</MenuItem>
                                        <MenuItem value={"Un-employed"}>Un-employed</MenuItem>
                                        <MenuItem value={"Retired"}>Retired</MenuItem>
                                        <MenuItem value={"Military"}>Military</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                    </Select>
                                    {formErrors.jobType && <FormHelperText>{formErrors.jobType}</FormHelperText>}
                                </FormControl>
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>Job Title</legend>
                                <TextField
                                    name="jobTitle"
                                    value={formData.jobTitle || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.jobTitle}
                                    helperText={formErrors.jobTitle}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>How Long have you worked here?</legend>
                                <FormControl fullWidth error={!!formErrors.workDuration} sx={{
                                    paddingBottom: "10px"
                                }}>
                                    <Select
                                        name="workDuration"
                                        value={formData.workDuration || ''}
                                        onChange={handleInputChange}
                                        sx={{
                                            marginTop: 1,
                                        }}
                                        size='small'
                                        className={styles.customTextfield}
                                    >
                                        {[...Array(100)].map((_, index) => (
                                            <MenuItem key={index + 1} value={index + 1}>
                                                {index + 1} Year{index + 1 > 1 ? 's' : ''}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {formErrors.workDuration &&
                                        <FormHelperText>{formErrors.workDuration}</FormHelperText>}
                                </FormControl>
                            </fieldset>
                            <fieldset className={styles.customFieldset}>
                                <legend>Primary Annual Income BEFORE taxes?</legend>
                                <TextField
                                    name="income"
                                    value={formData.income || ''}
                                    onChange={handleInputChange}
                                    fullWidth
                                    margin="normal"
                                    error={!!formErrors.income}
                                    helperText={formErrors.income}
                                    sx={{
                                        marginTop: 1
                                    }}
                                    size='small'
                                    className={styles.customTextfield}
                                />
                            </fieldset>
                            <Box display="flex" justifyContent="space-between" mt={2}>
                                <Button variant="contained" color="error"
                                    onClick={() => handlePrevAccordion('residence-info')}>
                                    Previous
                                </Button>
                                <Button variant="contained" color="secondary"
                                    onClick={() => handleNextAccordion('estimated-credit-score-info')}>
                                    Next
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'estimated-credit-score-info'}
                        onChange={handleAccordionChange('estimated-credit-score-info')}>
                        <AccordionSummary id="estimated-credit-score-info" expandIcon={<ExpandMore />} sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                            marginTop: 1,
                            marginBottom: expanded === 'estimated-credit-score-info' ? "10px" : "",
                            borderBottom: expanded === 'estimated-credit-score-info' ? "2px solid #eee" : ""
                        }}>
                            <h1 className='text-xl font-semibold font-sans' style={{ fontSize: '17px' }}>4: Estimated Credit Score</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <fieldset className={styles.customFieldset}>
                                <legend>Estimated Credit Score</legend>
                                <FormControl fullWidth error={!!formErrors.creditScore} sx={{
                                    paddingBottom: "10px"
                                }}>
                                    <Select
                                        name="creditScore"
                                        value={formData.creditScore || ''}
                                        onChange={handleInputChange}
                                        sx={{
                                            marginTop: 1
                                        }}
                                        size='small'
                                        className={styles.customTextfield}
                                    >
                                        <MenuItem value={"Rebuilding (<640)"}>Rebuilding (&lt;640)</MenuItem>
                                        <MenuItem value={"Fair (641-699)"}>Fair (641-699)</MenuItem>
                                        <MenuItem value={"Good (700-749)"}>Good (700-749)</MenuItem>
                                        <MenuItem value={"Excellent (750-850)"}>Excellent (750-850)</MenuItem>
                                        <MenuItem value={"Idk"}>I Don't Know</MenuItem>
                                    </Select>
                                    {formErrors.creditScore &&
                                        <FormHelperText>{formErrors.creditScore}</FormHelperText>}
                                </FormControl>
                            </fieldset>
                            <Box display="flex" justifyContent="space-between" mt={2}>
                                <Button variant="contained" color="error"
                                    onClick={() => handlePrevAccordion('employment-info')}>
                                    Previous
                                </Button>
                                <Button variant="contained" color="secondary"
                                    onClick={() => handleNextAccordion('co-applicant-info')}>
                                    Next
                                </Button>
                            </Box>

                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'co-applicant-info'}
                        onChange={handleAccordionChange('co-applicant-info')}>
                        <AccordionSummary id="co-applicant-info" expandIcon={<ExpandMore />} sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                            marginTop: 1,
                            marginBottom: expanded === 'co-applicant-info' ? "10px" : "",
                            borderBottom: expanded === 'co-applicant-info' ? "2px solid #eee" : ""
                        }}>

                            <h1 className='text-xl font-semibold font-sans' style={{ fontSize: '17px' }}>5:  Co-Application Info</h1>
                        </AccordionSummary>
                        <AccordionDetails>
                            <fieldset className={styles.customFieldset}>
                                <legend>Do you want to apply jointly?</legend>
                                <FormControl fullWidth sx={{
                                    paddingBottom: "10px"
                                }}>
                                    <Select
                                        name="jointApplication"
                                        value={isJointApplication ? "Yes" : "No"}
                                        onChange={handleJointApplicationChange}
                                        sx={{ marginTop: 1 }}
                                        className={styles.customTextfield}
                                        size='small'
                                    >
                                        <MenuItem value="Yes">Yes</MenuItem>
                                        <MenuItem value="No">No</MenuItem>
                                    </Select>
                                </FormControl>
                            </fieldset>

                            {isJointApplication && (
                                <>
                                    <fieldset className={styles.customFieldset}>
                                        <legend>Co-Applicant First Name</legend>
                                        <TextField
                                            name="coApplicantFirstName"
                                            value={formData.coApplicantFirstName || ''}
                                            onChange={handleInputChange}
                                            fullWidth
                                            margin="normal"
                                            error={!!formErrors.coApplicantFirstName}
                                            helperText={formErrors.coApplicantFirstName}
                                            sx={{ marginTop: 1 }}
                                            className={styles.customTextfield}
                                            size='small'
                                        />
                                    </fieldset>
                                    <fieldset className={styles.customFieldset}>
                                        <legend>Co-Applicant Last Name</legend>
                                        <TextField
                                            name="coApplicantLastName"
                                            value={formData.coApplicantLastName || ''}
                                            onChange={handleInputChange}
                                            fullWidth
                                            margin="normal"
                                            error={!!formErrors.coApplicantLastName}
                                            helperText={formErrors.coApplicantLastName}
                                            sx={{ marginTop: 1 }}
                                            className={styles.customTextfield}
                                            size='small'
                                        />
                                    </fieldset>
                                    <fieldset className={styles.customFieldset}>
                                        <legend>Co-Applicant Email</legend>
                                        <TextField
                                            name="coApplicantEmail"
                                            value={formData.coApplicantEmail || ''}
                                            onChange={handleInputChange}
                                            fullWidth
                                            margin="normal"
                                            error={!!formErrors.coApplicantEmail}
                                            helperText={formErrors.coApplicantEmail}
                                            sx={{ marginTop: 1 }}
                                            className={styles.customTextfield}
                                            size='small'
                                        />
                                    </fieldset>
                                    <fieldset className={styles.customFieldset}>
                                        <legend>Co-Applicant Phone Number</legend>
                                        <TextField
                                            name="coApplicantPhone"
                                            value={formData.coApplicantPhone || ''}
                                            onChange={handleInputChange}
                                            fullWidth
                                            margin="normal"
                                            error={!!formErrors.coApplicantPhone}
                                            helperText={formErrors.coApplicantPhone}
                                            sx={{ marginTop: 1 }}
                                            className={styles.customTextfield}
                                            size='small'
                                        />
                                    </fieldset>
                                    <fieldset className={styles.customFieldset}>
                                        <legend>Address</legend>
                                        <TextField
                                            name="coApplicantAddress"
                                            value={formData.coApplicantAddress}
                                            onChange={handleInputChange}
                                            fullWidth
                                            margin="normal"
                                            multiline
                                            rows={4}
                                            error={!!formErrors.coApplicantAddress}
                                            helperText={formErrors.coApplicantAddress}
                                            className={styles.customTextfield}
                                            size='small'
                                        />
                                    </fieldset>
                                    <Box display="flex" justifyContent="space-between" mt={2}>
                                        <Button variant="contained" color="error"
                                            onClick={() => handlePrevAccordion('estimated-credit-score-info')}>
                                            Previous
                                        </Button>

                                        <Button variant="contained" color="secondary"
                                            onClick={() => handleNextAccordion('review-application')}>
                                            Next
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'review-application'}
                        onChange={handleAccordionChange('review-application')}>
                        <AccordionSummary
                            id="review-application"
                            expandIcon={<ExpandMore />}
                            sx={{
                                marginTop: 1,
                                marginBottom: expanded === 'review-application' ? "10px" : "",
                                borderBottom: expanded === 'review-application' ? "2px solid #eee" : ""
                            }}
                        >

                            <h1 className='font-semibold font-sans' style={{ fontSize: '17px' }}>6: Review Application</h1>

                        </AccordionSummary>
                        <AccordionDetails>

                            <div className='p-5'>
                                <h4 style={{ fontSize: '18px', fontWeight: '600' }}>Terms and Conditions</h4>
                                <p>By submitting my information, I certify that all of the information and statements in this form are true and complete. I acknowledge that I am requesting an auto financing pre-qualification offer. I understand that this form is not an application for credit. By providing my email address and/or cell phone number, I am giving my prior express consent to receive email messages, calls and text messages</p>
                                <FormGroup sx={{ marginTop: 2 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                name="agreeToTerms"
                                                checked={formData.agreeToTerms}
                                                onChange={handleCheckboxInputChange}
                                            />
                                        }
                                        label="I have read and agree to the terms and conditions Above."
                                    />
                                    {formErrors.agreeToTerms &&
                                        <FormHelperText error>{formErrors.agreeToTerms}</FormHelperText>}
                                </FormGroup>
                            </div>

                            <Box display="flex" justifyContent="space-between" mt={2}>
                                <Button variant="contained" color="error"
                                    onClick={() => handlePrevAccordion('co-applicant-info')}>
                                    Previous
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleReview}
                                    disabled={isSubmitting}
                                >
                                    {isSubmitting ? 'Processing...' : 'Review'}
                                </Button>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </form>
            )}
        </div>
    );
}
