import BannerImage1 from "../assets/img/banner-image-m-1-nobg.png";
import BannerImage2 from "../assets/img/banner-image-m-2-transparent.png";
import BannerImage3 from "../assets/img/banner-image-m-3.jpg";
import BannerImage4 from "../assets/img/banner-image-m-4-nobg.png";
import BannerImage5 from "../assets/img/banner-image-m-5.jpg";
import SearchBar from "./SearchBar";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

const images = [
  BannerImage3,
  BannerImage1,
  BannerImage2,
  BannerImage4,
  BannerImage5,
];

export default function Banner() {
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  // handle search
  const handleSearch = (value) => navigate(`/search/brand/${value}`)

  return (
    <div className="grid">
      <div
        className="absolute grid w-full md:grid-cols-3 z-10"
        style={{ top: "20%" }}
      >
        <div></div>
      </div>
      <div className="py-4 w-11/12 md:w-2/6 mx-auto">
        <SearchBar onSubmit={handleSearch} />
      </div>
      <div className="relative w-full overflow-hidden">
        <div
          className="whitespace-nowrap transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Slide ${index}`}
              className="inline-block w-full"
            />
          ))}
        </div>

        <button
          onClick={prevSlide}
          className="absolute left-0 top-1/2 transform -translate-y-1/2  text-black px-2 py-1 text-7xl"
        >
          ‹
        </button>
        <button
          onClick={nextSlide}
          className="absolute right-0 top-1/2 transform -translate-y-1/2  text-black px-2 py-1 text-7xl"
        >
          ›
        </button>
      </div>

      {/* <div className="h-2 bg-secondary"></div> */}
    </div>
  );
}
