import SUV from "../assets/img/suv.png";
import Sedan from "../assets/img/sedan.png";
import Convertile from "../assets/img/convertible.png";
import Truck from "../assets/img/truck.png";
import Hatchback from "../assets/img/hatchback.png";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";

export default function CarouselBodyStyle() {
  const navigate = useNavigate();

  const handleBodyClick = (bodyStyle) => {
    navigate(`/search/body/${bodyStyle}`);
  };

  return (
    <Fragment>
      <p className="text-primary text-2xl font-bold text-center">
        Shop by Body Style
      </p>
      <div className="flex items-center md:justify-center space-x-6 overflow-scroll scrollbar-hide w-4/5 pb-2">
        <div className="card" onClick={() => handleBodyClick('SUV')}>
          <img src={SUV} alt="..." className="h-42 mx-auto" />
          <p className="text-center text-white font-bold">SUV</p>
        </div>
        <div className="card" onClick={() => handleBodyClick('Sedan')}>
          <img src={Sedan} alt="..." className="h-42 mx-auto" />
          <p className="text-center text-white font-bold">Sedan</p>
        </div>
        <div className="card" onClick={() => handleBodyClick('Convertible')}>
          <img src={Convertile} alt="..." className="h-42 mx-auto" />
          <p className="text-center text-white font-bold">Convertible</p>
        </div>
        <div className="card" onClick={() => handleBodyClick('Truck')}>
          <img src={Truck} alt="..." className="h-42 mx-auto" />
          <p className="text-center text-white font-bold">Truck</p>
        </div>
        <div className="card" onClick={() => handleBodyClick('Hatchback')}>
          <img src={Hatchback} alt="..." className="h-42 mx-auto" />
          <p className="text-center text-white font-bold">Hatchback</p>
        </div>
      </div>
    </Fragment>
  );
}
