import { useCallback, useEffect, useState } from "react";
import { getPrice } from "../../util/getPrice";
import ImageCarousel from "../UI/ImageCarousel";
import heatedSeatsIcon from "../../assets/img/icons/heated-seats.png";
import VehicleFeature from "../UI/VehicleFeature";
import rearCamera from "../../assets/img/icons/rear-camera.png";
import hud from "../../assets/img/icons/hud.png";
import BlindSpotAssist from "../../assets/img/icons/blind-spot-assist.png";
import ambientLight from "../../assets/img/icons/ambient-light.png";
import androidAuto from "../../assets/img/icons/android-auto.png";
import carplay from "../../assets/img/icons/carplay.png";
import navigation from "../../assets/img/icons/navigation.png";
import remoteStart from "../../assets/img/icons/remote-start.png";
import sunroof from "../../assets/img/icons/sunroof.png";
import bluetooth from "../../assets/img/icons/bluetooth.png";
import wirelessCharging from "../../assets/img/icons/wireless-charging.png";
import { Button } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { NotFound } from "../404";
import Buy from "../BuyModal";
import { VehicleInfoPreloader } from "../preloader/vehicle-info.preloader";

export default function Vehicle() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [vehicle, setVehicle] = useState(null);
    const [open, setOpen] = useState(false);
    const id = searchParams.get("id");

    // Fetch vehicle information
    const fetchVehicle = useCallback(async ({ id }) => {
        const data = await getDoc(doc(db, "Vehicles", id));
        if (data.data()) {
            setVehicle({ id: data.id, ...data.data() });
        }
        setLoading(false);
    }, [])

    useEffect(() => {
        if (id) { fetchVehicle({ id }) }
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [id, fetchVehicle]);

    return (
        <>
            {/* Loading preview */}
            {loading && !vehicle ?
                <VehicleInfoPreloader />
                : null
            }

            {/* Not found preview */}
            {!loading && !vehicle ? <div className="container">
                <div className="h-[70vh] grid items-center">
                    <NotFound message={"Vehicle information not found!"} back_url={"/search"} />
                </div>
            </div> : null
            }

            {!loading && vehicle ?
                <>
                    <div className="min-h-screen p-4 bg-gray-100">
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            {/* Carousel container */}
                            <div className="rounded-3xl p-4 shadow-md bg-white">
                                <ImageCarousel images={vehicle?.images} />
                            </div>

                            {/* Content container */}
                            <div className="rounded-3xl p-4 shadow-md bg-white">
                                <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
                                    <div className="w-full h-72 overflow-hidden rounded-2xl hidden lg:block">
                                        <img src={vehicle.images[0]} alt="..." className="min-w-full min-h-full" />
                                    </div>
                                    <div className="grid content-start gap-4">
                                        <div>
                                            <p className="font-bold text-primary">{vehicle.make}</p>
                                            <p className="text-2xl font-light text-primary">
                                                {vehicle.year} {vehicle.model}
                                            </p>
                                            <p className="text-sm font-semibold text-gray-500">
                                                {vehicle.trim}
                                            </p>
                                        </div>
                                        <div className="grid grid-cols-2">
                                            <p className="text-sm text-gray-500">
                                                Mileage: {getPrice(vehicle.mileage)}mi
                                            </p>
                                            <p className="text-sm text-gray-500">Drive: {vehicle.drive}</p>
                                            <p className="text-sm text-gray-500">Fuel: {vehicle.fuel}</p>
                                            <p className="text-sm text-gray-500">Seats: {vehicle.seats}</p>
                                            <p className="text-sm text-gray-500">Doors: {vehicle.doors}</p>
                                            <p className="text-sm text-gray-500">Color: {vehicle.color}</p>
                                        </div>

                                        <p className="text-xl">
                                            {vehicle.originalprice ? <span className="line-through text-red-500">${getPrice(vehicle.originalprice)}{' '}</span> : null}
                                            <span className="font-bold text-primary">${getPrice(vehicle.price)}</span>
                                        </p>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-4">
                                    <Button variant="contained" color="secondary"
                                        onClick={() => setOpen(true)}>
                                        Buy
                                    </Button>
                                    <Button variant="contained" color="primary"
                                        onClick={() => navigate("/contact")}>
                                        Schedule Test Drive
                                    </Button>
                                    <div className="col-span-2 flex justify-center">
                                        <Button variant="text" onClick={() => navigate("/contact")}>Contact Us</Button>
                                    </div>
                                </div>


                                <hr className="my-4" />
                                <p className="text-xl font-semibold text-primary">
                                    Vehicle Features
                                </p>

                                <div className="grid py-4 md:grid-cols-3 gap-y-4 gap-x-2">
                                    <VehicleFeature
                                        icon={heatedSeatsIcon}
                                        title="Heated Seats"
                                        check={true}
                                    />
                                    <VehicleFeature
                                        icon={rearCamera}
                                        title="Rear Camera"
                                        check={true}
                                    />
                                    <VehicleFeature icon={hud} title="Heads Up Display" check={false} />
                                    <VehicleFeature
                                        icon={BlindSpotAssist}
                                        title="Blind Spot Assist"
                                        check={true}
                                    />
                                    <VehicleFeature
                                        icon={ambientLight}
                                        title="Ambient Lighting"
                                        check={true}
                                    />
                                    <VehicleFeature
                                        icon={androidAuto}
                                        title="Android Auto"
                                        check={true}
                                    />
                                    <VehicleFeature icon={carplay} title="Apple CarPlay" check={true} />
                                    <VehicleFeature icon={navigation} title="Navigation" check={true} />
                                    <VehicleFeature
                                        icon={remoteStart}
                                        title="Remote Start"
                                        check={true}
                                    />
                                    <VehicleFeature icon={sunroof} title="Sunroof" check={true} />
                                    <VehicleFeature icon={bluetooth} title="Bluetooth" check={true} />
                                    <VehicleFeature
                                        icon={wirelessCharging}
                                        title="Wireless Charging"
                                        check={true}
                                    />
                                </div>

                                <hr className="my-4" />

                                <p className="text-sm leading-relaxed text-gray-500">
                                    The price listed above does not include taxes, registration, or
                                    dealer fees. Please contact us for more information. Taxes and
                                    registration fees are calculated based on the state and county of
                                    registration. Dealer fees are waived for all online sales.
                                </p>
                            </div>
                        </div>
                    </div>

                    <Buy openModal={open} vehicleId={vehicle.id} onClose={() => setOpen(false)} /></>
                : null
            }


        </>
    );
}
