
export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    cleaned = cleaned.replace("+", "");
    if (cleaned.length > 10) {
        cleaned = cleaned.slice(cleaned.length - 10, cleaned.length)
    }
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumberString;
  }