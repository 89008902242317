import { Fragment, useState } from "react";
import { Button } from "@headlessui/react";
import { CarSelectionModal } from "../modals/car-selection.modal";


export const CarSelection = ({ onSelected }) => {
    const [open, setOpen] = useState(false)
    const [selectedCar, setSelectedCar] = useState(null)

    // handle car selection
    const handleCarSelection = (value) => {
        onSelected(value)
        setSelectedCar(value)
    }

    return (
        <>
            <div className='p-5 rounded-xl flex gap-2 cursor-pointer transition-all border border-[#8ecddd83] bg-[#8ecddd59] hover:bg-[#8ecddd83]'
                onClick={() => setOpen(true)}>
                {selectedCar ?
                    <div className="grow">
                        <SelectedItem label={selectedCar.label} values={selectedCar.values} image={selectedCar.image} />
                    </div> :
                    <div className="grow">
                        <Button className="w-full font-semibold">BEGIN PROCESS</Button>
                    </div>
                }
            </div>

            <CarSelectionModal open={open} onHide={() => setOpen(false)} onSelect={handleCarSelection} />
        </>
    )
}


// Selected item
const SelectedItem = ({ image, label, values }) => {
    return (
        <div className="flex w-full items-center gap-3">
            {image ?
                <div className='flex-none !w-10 !h-10 rounded-md overflow-hidden'>
                    <img src={image} alt='...' className="min-w-full min-h-full" />
                </div>
                : null
            }
            <div className="grow">
                <p className="font-semibold text-sm capitalize text-black">{label || ""}</p>
                <div className="flex flex-wrap md:flex-nowrap gap-2 text-xs text-gray-500">
                    {values && values.length ? values.map((value, k) =>
                        <Fragment key={k}>
                            <div>{value}</div>
                            {k + 1 < values.length && <div className='w-1 h-1 mt-1.5 rounded-full bg-gray-500' />}
                        </Fragment>
                    ) : null}
                </div>
            </div>
        </div>
    )
}