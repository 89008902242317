import "./App.css";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import Navbar from "./components/Navbar";
import Home from "./components/pages/Home";
import Footer from "./components/Footer";
import Search from "./components/pages/Search";
import Vehicle from "./components/pages/Vehicle";
import Contact from "./components/pages/Contact";
import NotFound from "./components/pages/404";
import Sell from "./components/pages/Sell";
import PrivacyStatement from "./components/pages/privacy-statement";
import Finance from "./components/pages/Finance";

const theme = createTheme({
  palette: {
    primary: {
      main: "#22668D",
    },
    secondary: {
      main: "#8ECDDD",
    },
    tertiary: {
      main: "#FFCC70",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={<Search />} />
        <Route path="/search/:type/:param" element={<Search />} />
        <Route path="/vehicle" element={<Vehicle />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/privacy-statement" element={<PrivacyStatement />} />
        <Route path="/finance" element={<Finance />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
