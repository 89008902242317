import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import NotfoundIcon from '../../assets/img/icons/404.png'

export const NotFound = ({ message, back_url }) => {
    return (
        <div className="w-full max-w-sm mx-auto p-6 h-80 grid justify-center items-center text-center">
            <div>
                <img src={NotfoundIcon} alt="Not found" className="mx-auto" />
                <div className="my-5">
                    <p className="text-xl font-bold mb-1 text-red-400">Opps!</p>
                    <p className="text-sm mb-4 text-gray-500">{message || "Data not found!"}</p>
                </div>
                <Link to={back_url}><Button variant="outlined" size="large" type="button" className="">Go Back</Button></Link>
            </div>
        </div>
    )
}