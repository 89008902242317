import CallImage from "../../assets/img/customer-call.webp";
import { MdTextsms } from "react-icons/md";
import { BiSolidBusiness, BiSupport } from "react-icons/bi";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../../context/data-context";
import { sendMessage } from "../../api/send";
import { addDoc, doc } from "firebase/firestore";
import { db } from "../../firebase";
import { ContactForm } from "../forms/contact.form";
import { ContactSuccessModal } from "../modals/contact-success.modal";
import { WarningModal } from "../modals/warning.modal";

export default function Contact() {
  const { info } = useContext(DataContext);
  const [isSent, setSent] = useState(false);
  const [warning, setWarning] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const submitHandler = async (values) => {
    try {
      setLoading(true);
      setWarning(false);
      let input = { ...values };
      const response = await sendMessage(input);
      if (response && response.status === 200) {
        input.date = new Date();
        await addDoc(doc(db, "Messages"), input);
        setSent(true);
      }
    } catch (err) {
      console.log(err);
      setWarning(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setWarning(false);
  }, []);

  return (
    <div className="bg-gray-200">
      <div className="container mx-auto py-12">

        {/* Page title container */}
        <div className="p-4 border-t-8 rounded-lg shadow-md mb-6 bg-white border-secondary">
          <p className="text-2xl font-bold text-primary">Contact Us</p>
        </div>

        {/* Contact form & banner container */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 rounded-lg shadow-md p-8 mb-6 bg-white">
          <div>
            <ContactForm loading={isLoading} onSubmit={submitHandler} />
          </div>
          <div className="hidden lg:block"><img src={CallImage} className="w-full" alt="Contact" /></div>
        </div>

        {/* Location & other information */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 rounded-lg shadow-md p-10 bg-white">
          <div className="text-center">
            <a
              href="https://maps.app.goo.gl/t4WJNVU4qoAnb12u9"
              target="_blank"
              className="text-sm text-gray-700"
              rel="noreferrer"
            >
              <BiSolidBusiness size={45} className="mx-auto mb-2 text-primary" />
              <div className="w-28 mx-auto">
                <p>
                  Visit us during business hours at {info.businessAddress}
                </p>
              </div>
            </a>
          </div>

          <div className="text-center">
            <a
              href={`tel:${info.phone}`}
              className="text-sm text-gray-700"
            >
              <BiSupport size={45} className="mx-auto mb-2 text-primary" />
              <div className="w-28 mx-auto">
                <p>Call us for any further information at {info.phone}</p>
              </div>
            </a>
          </div>

          <div className="text-center">
            <a
              href={`sms:${info.phone}`}
              className="text-sm text-gray-700"
            >
              <MdTextsms size={45} className="mx-auto mb-2 text-primary" />
              <div className="w-28 mx-auto">
                <p>Got inquires? Send us a message at {info.phone}</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* Contact message success modal */}
      <ContactSuccessModal open={isSent} onHide={() => setSent(false)} />

      {/* Contact message warning modal */}
      <WarningModal open={warning} onHide={() => setWarning(false)} message={"Your message could not be sent!"} />



    </div>
  );
}
