import { useForm } from "react-hook-form";
import { MdCall, MdEmail } from "react-icons/md";

export const ContactForm = ({ onSubmit, loading }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleSubmission = (data) => onSubmit(data)

    return (
        <form onSubmit={handleSubmit(handleSubmission)}>
            {/* Name input control */}
            <div className="mb-6">
                {errors.name && errors.name.message ?
                    <p className="font-light text-base mb-1 text-red-500">{errors.name.message}</p> :
                    <p className="font-light text-base mb-1 text-slate-500">What is your name?</p>
                }
                <input
                    type="text"
                    placeholder=""
                    className="w-full p-3 rounded-lg bg-gray-200"
                    {...register("name", { required: "Name is required" })}
                />
            </div>

            {/* Vehicle input control */}
            <div className="mb-6">
                <p className="font-light text-base mb-1 text-slate-500">Which vehicle are you interested in?</p>
                <input
                    type="text"
                    placeholder="Make, Model, Year"
                    className="w-full p-3 rounded-lg bg-gray-200"
                    {...register("vehicle")}
                />
            </div>

            {/* Message input control */}
            <div className="mb-6">
                <p className="font-light text-base mb-1 text-slate-500">Message</p>
                <textarea
                    placeholder="Optional"
                    className="w-full p-3 rounded-lg bg-gray-200"
                    {...register("message")}
                />
            </div>

            <div className="mb-8">
                {errors.phone && errors.phone.message ?
                    <p className="font-light text-base mb-1 text-red-500">{errors.phone.message}</p> :
                    <p className="font-light text-base mb-1 text-slate-500">How can we reach out to you?</p>
                }
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    {/* Phone number input control */}
                    <div className="flex gap-4">
                        <MdCall size={30} className="mt-2 text-primary" />
                        <input
                            type="text"
                            placeholder="(###) ###-####"
                            className="w-full p-3 rounded-lg bg-gray-200"
                            {...register("phone", {
                                required: "Phone number is required",
                                pattern: {
                                    value: /^\(\d{3}\) \d{3}-\d{4}$/,
                                    message: "Phone number format is invalid"
                                }
                            })}
                        />
                    </div>

                    {/* Email input control */}
                    <div className="flex gap-4">
                        <MdEmail size={30} className="mt-2 text-primary" />
                        <input
                            type="email"
                            placeholder="name@example.com"
                            className="w-full p-3 rounded-lg bg-gray-200"
                            {...register("email")}
                        />
                    </div>
                </div>
            </div>

            {/* Form submit button */}
            <button className="w-full p-2.5 text-white rounded-md bg-primary hover:bg-opacity-90 disabled:bg-secondary" type="submit" disabled={loading}>
                {loading ? "Sending..." : "Submit"}
            </button>
        </form>
    );
};
