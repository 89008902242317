
export const VehicleInfoPreloader = () => {
    return (
        <div>
            <div className="min-h-screen p-4 bg-gray-100">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                    <div className="rounded-3xl p-4 shadow-md bg-white">
                        <div className="w-full h-52 lg:h-96 animate-pulse rounded-2xl bg-slate-300" />
                    </div>
                    <div>
                        <div className="rounded-3xl p-4 shadow-md bg-white">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="w-full h-60 animate-pulse rounded-2xl bg-slate-300" />
                                <div>
                                    <div className="w-40 h-3 mb-3 animate-pulse rounded-full bg-slate-300" />
                                    <div className="w-52 h-5 mb-3 animate-pulse rounded-full bg-slate-300" />
                                    <div className="w-1/3 h-3 mb-3 animate-pulse rounded-full bg-slate-300" />
                                    <br />
                                    <div className="w-4/5 h-2 mb-3 animate-pulse rounded-full bg-slate-300" />
                                    <div className="w-4/6 h-2 mb-3 animate-pulse rounded-full bg-slate-300" />
                                    <div className="w-4/12 h-2 mb-3 animate-pulse rounded-full bg-slate-300" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}