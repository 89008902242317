
import { BiHide, BiShow } from "react-icons/bi";
import { useState, useEffect, useContext } from "react";
import { DataContext } from "../../context/data-context";
import { useParams } from "react-router-dom";
import Accordion from "../Accordion";
import SearchBar from "../SearchBar";
import VehicleCard from "../UI/VehicleCard";
import SearchIcon from "../../assets/img/icons/search.png"
import { VehicleCardPreloader } from "../preloader/vehicle-card.preloader";

export default function Search() {
    const { type, param } = useParams();
    const { vehicles } = useContext(DataContext);
    const [showFilters, setShowFilters] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [filteredVehicles, setFilteredVehicles] = useState([]);

    const searchHandler = (input) => {
        const filtered = vehicles.filter((vehicle) => {
            return (
                vehicle.make.toLowerCase().includes(input.toLowerCase()) ||
                vehicle.model.toLowerCase().includes(input.toLowerCase()) ||
                vehicle.year.toString().includes(input)
            );
        });
        setFilteredVehicles(filtered);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (param) {
            setLoading(true)
            setFilteredVehicles([])
            const filtered = vehicles.filter((vehicle) => {
                if (type === "brand") {
                    return vehicle.make.toLowerCase() === param.toLowerCase();
                } else if (type === "body") {
                    return vehicle.body.toLowerCase() === param.toLowerCase();
                }
                return [];
            });

            if (filtered && filtered.length) {
                setTimeout(() => {
                    setFilteredVehicles(filtered);
                    setLoading(false)
                }, 300);
            } else {
                setTimeout(() => {
                    setLoading(false);
                    setFilteredVehicles([]);
                }, 300);
            }
        } else {
            if (vehicles && vehicles.length) {
                setTimeout(() => {
                    setFilteredVehicles(vehicles);
                    setLoading(false)
                }, 300);
            } else {
                setTimeout(() => {
                    setLoading(false)
                    setFilteredVehicles([]);
                }, 300);
            }
        }
    }, [vehicles, type, param]);

    const applyFilters = (filters) => {
        let sortedVehicles = [...filteredVehicles];

        if (filters.price) {
            sortedVehicles.sort((a, b) =>
                filters.price === "priceLowToHigh"
                    ? a.price - b.price
                    : b.price - a.price
            );
        }

        if (filters.mileage) {
            sortedVehicles.sort((a, b) =>
                filters.mileage === "mileageLowToHigh"
                    ? a.mileage - b.mileage
                    : b.mileage - a.mileage
            );
        }

        if (filters.year) {
            sortedVehicles.sort((a, b) =>
                filters.year === "yearOldToNew" ? a.year - b.year : b.year - a.year
            );
        }

        setFilteredVehicles(sortedVehicles);
    };

    const resetFilters = () => {
        setFilteredVehicles(vehicles)
    }

    return (
        <div className="container py-6">
            <div className="lg:flex gap-6">
                {/* Accordion container */}
                <div className="flex-none w-full lg:w-[300px] mb-4 lg:mb-0">
                    <div className={`flex px-4 py-3 justify-between rounded-t-xl bg-stone-200 ${!showFilters ? "rounded-b-xl" : ""}`}>
                        <p className="text-lg font-medium text-primary">Filters</p>

                        {showFilters ? (
                            <BiHide
                                size={21}
                                onClick={() => setShowFilters(false)}
                                className="lg:hidden mt-1 text-primary"
                            />
                        ) : (
                            <BiShow
                                size={21}
                                onClick={() => setShowFilters(true)}
                                className="lg:hidden mt-1 text-primary"
                            />
                        )}
                    </div>
                    {showFilters && <Accordion onApply={applyFilters} onReset={resetFilters} />}
                </div>

                {/* Items container */}
                <div className="grow">
                    <div className="mb-4 lg:mb-6"><SearchBar onSubmit={searchHandler} /></div>


                    {/* Preview the preloader */}
                    {isLoading && !filteredVehicles.length ?
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
                            <VehicleCardPreloader />
                            <VehicleCardPreloader />
                            <VehicleCardPreloader />
                            <VehicleCardPreloader />
                            <VehicleCardPreloader />
                            <VehicleCardPreloader />
                            <VehicleCardPreloader />
                            <VehicleCardPreloader />
                        </div>
                        : null
                    }


                    {/* Preview not found message */}
                    {!isLoading && !filteredVehicles.length ?
                        <div className="w-full text-center py-10">
                            <img src={SearchIcon} alt="Empty" className="mx-auto w-16 h-16 mb-4" />
                            <p className="text-base font-bold text-black">No results found.</p>
                            <p className="text-sm text-slate-400">Adjust you filter and try again!</p>
                        </div>
                        : null
                    }

                    {/* Preview found results */}
                    {!isLoading && filteredVehicles.length ?
                        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6">
                            {filteredVehicles.map((vehicle) => (
                                <VehicleCard vehicle={vehicle} />
                            ))}
                        </div>
                        : null}
                </div>
            </div>
        </div>
    );
}


