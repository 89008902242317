import React, { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel,
    FormLabel, Radio,
    RadioGroup, Snackbar
} from '@mui/material';
import CashForm from "./CashForm";
import FinancingForm from "./FinancingForm";
import styles from './financingForm.module.css'

export default function Buy({ openModal, vehicleId, onClose }) {
    const CASH = "cash";
    const FINANCING = "financing";
    const [selectedOption, setSelectedOption] = useState(CASH);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleFormSubmit = () => {
        setSnackbarOpen(true);
        onClose();
    };

    const renderContent = () => {
        if (selectedOption === CASH) {
            return <CashForm vehicleId={vehicleId} onFormSubmit={handleFormSubmit} paymentMethod={CASH} />;
        } else if (selectedOption === FINANCING) {
            return <FinancingForm vehicleId={vehicleId} onFormSubmit={handleFormSubmit} paymentMethod={FINANCING} />;
        }
        return null;
    };

    return (
        <div>
            <Dialog open={openModal} onClose={onClose} sx={{ "& .MuiDialog-paper": { width: "450px", maxWidth: "none", height: "95vh" } }}>
                <DialogActions className='absolute top-0 end-0'>
                    <Button className='' onClick={onClose} color="primary">
                        X
                    </Button>
                </DialogActions>
                <DialogTitle className='border-b-2'>
                    <h1 className='text-xl font-semibold font-sans'>Do you want to buy a car?</h1>
                </DialogTitle>
                <DialogContent className={styles.smallScreenModal} style={{
                    height: '500px',
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    marginTop: "10px"
                }}>
                    <FormControl sx={{
                        marginBottom: "10px"
                    }} component="fieldset">
                        <FormLabel component="legend" sx={{ fontSize: '14px' }}>Select An Option</FormLabel>
                        <RadioGroup
                            aria-label="options"
                            name="options"
                            value={selectedOption}
                            onChange={handleOptionChange}
                            style={{ display: 'flex', flexDirection: 'row' }}
                        >
                            <FormControlLabel value={CASH} control={<Radio />} label="Cash" />
                            <FormControlLabel value={FINANCING} control={<Radio />} label="Financing" />
                        </RadioGroup>
                    </FormControl>

                    {renderContent()}

                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                message="Successfully Received!"
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            />
        </div>
    );
}
