import { Fragment, useContext, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { DataContext } from "../../context/data-context";
import { RadioInput } from '../inputs/radio.input';
import { getPrice } from '../../util/getPrice';
import Accordion from "../Accordion";

export const CarSelectionModal = ({ open, onHide, onSelect }) => {
    const { vehicles } = useContext(DataContext);
    const [options, setOptions] = useState([]);
    const [selectedValue, setSelectedValue] = useState(null);
    const [error, setError] = useState(false);

    const modifyVehicle = (data) => {
        if (data?.length) {
            return data.map(item => {
                return {
                    label: item.make,
                    values: [
                        `${item?.model} ${item?.year}`,
                        `${item?.trim} - ${getPrice(item?.mileage)}mi`,
                        `$${getPrice(item?.price || 0)}`
                    ],
                    image: item?.images?.[0] || '',
                    ...item
                };
            });
        }
        return [];
    };

    useEffect(() => {
        if (vehicles?.length) {
            setOptions(modifyVehicle(vehicles));
        }
    }, [vehicles]);

    const applyFilters = (filters) => {
        let sortedVehicles = [...options];

        if (filters.price) {
            sortedVehicles.sort((a, b) =>
                filters.price === "priceLowToHigh"
                    ? a.price - b.price
                    : b.price - a.price
            );
        }

        if (filters.mileage) {
            sortedVehicles.sort((a, b) =>
                filters.mileage === "mileageLowToHigh"
                    ? a.mileage - b.mileage
                    : b.mileage - a.mileage
            );
        }

        if (filters.year) {
            sortedVehicles.sort((a, b) =>
                filters.year === "yearOldToNew" ? a.year - b.year : b.year - a.year
            );
        }

        if (filters.brand) {
            sortedVehicles = modifyVehicle(vehicles).filter(
                (vehicle) => vehicle.make.toLowerCase() === filters.brand.toLowerCase()
            );
        }

        if (filters.body) {
            sortedVehicles = modifyVehicle(vehicles).filter(
                (vehicle) => vehicle.body.toLowerCase() === filters.body.toLowerCase()
            );
        }

        setOptions(sortedVehicles);
    };

    const resetFilters = () => setOptions(modifyVehicle(vehicles));

    const handleSave = () => {
        if (!selectedValue) {
            return setError(true);
        }

        onSelect(selectedValue);
        onHide();
    };

    return (
        <Transition show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={() => { }}>
                {/* Overlay */}
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-60" aria-hidden="true" />
                </Transition.Child>

                {/* Panel */}
                <div className="fixed inset-0 flex items-center justify-center p-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className="relative grid w-full md:max-w-2xl lg:max-w-3xl rounded-xl overflow-hidden bg-white max-h-screen">
                            {/* Modal header */}
                            <div className='text-center border-b p-4'>
                                <p className='text-lg font-semibold'>Please use filters to select your favorite car</p>
                            </div>

                            {/* Modal body with scrollable content */}
                            <div className="p-6 bg-white max-h-[calc(100vh-150px)] overflow-y-auto">
                                {/* Filter items */}
                                <div className='mb-6'><Accordion isFinancing={true} onApply={applyFilters} onReset={resetFilters} /></div>

                                {/* Selection radio input */}
                                {error ? <p className='text-sm mb-2 text-red-400'>Please select a car.</p> : null}
                                <RadioInput items={options} onSelect={value => {
                                    setSelectedValue(value);
                                    setError(false);
                                }} />

                                {/* Button group */}
                                <div className='flex justify-end gap-2 pt-2'>
                                    <button
                                        type='button'
                                        className='px-6 py-3 uppercase rounded-lg font-semibold text-sm border border-red-400 text-red-500 transition-all hover:bg-red-100'
                                        onClick={() => {
                                            setSelectedValue(null);
                                            onHide();
                                        }}
                                    >Cancel</button>
                                    <button
                                        type='button'
                                        className='px-6 py-3 uppercase rounded-lg font-semibold text-sm text-white bg-primary'
                                        onClick={handleSave}
                                    >Select Car</button>
                                </div>
                            </div>

                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};
