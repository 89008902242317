import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { TextField, Button } from '@mui/material';
import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import * as emailjs from "emailjs-com";

export default function CashForm({ vehicleId, onFormSubmit, paymentMethod }) {
    const email_service_id = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const email_public_key = process.env.REACT_APP_EMAIL_PUBLIC_KEY;
    const email_template_id = process.env.REACT_APP_CASH_EMAIL_TEMPLATE_ID;
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const sendEmail = async (formData, vehicle) => {
        const templateParams = {
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            paymentMethod: formData.paymentMethod,
            body: vehicle.body,
            make: vehicle.make,
            trim: vehicle.trim,
            color: vehicle.color,
            model: vehicle.model,
            mileage: vehicle.mileage,
            vin: vehicle.vin,
            year: vehicle.year,
            price: new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(vehicle.price)
        };
        return await emailjs.send(email_service_id, email_template_id, templateParams, email_public_key);
    };

    const fetchVehicle = async ({ vehicleId }) => {
        const data = await getDoc(doc(db, "Vehicles", vehicleId));
        return { id: data.id, ...data.data() };
    };

    const handleSubmission = async (data) => {
        try {
            setIsSubmitting(true);
            const addDocResponse = await addDoc(collection(db, "Buy"), { ...data, paymentMethod: paymentMethod, vehicleId: vehicleId })
            if (addDocResponse) {
                const vehicleResponse = await fetchVehicle({ vehicleId: vehicleId });
                if (vehicleResponse) {
                    const emailSendResponse = await sendEmail({ ...data, paymentMethod }, vehicleResponse);
                    if (emailSendResponse && emailSendResponse.status === 200) {
                        reset();
                        onFormSubmit();
                    }
                }
            }
        } catch (error) {
            console.error("Error adding document: ", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <form onSubmit={handleSubmit(handleSubmission)}>
            <div className="mb-4">
                {errors.name?.message ?
                    <p className="text-xs mb-1 text-red-500">{errors.name.message}</p> :
                    <p className="text-xs mb-1 text-slate-500">Name</p>
                }
                <TextField
                    {...register('name', { required: 'Name is required' })}
                    fullWidth
                    error={!!errors.name}
                    size="small"
                />
            </div>

            <div className="mb-4">
                {errors.email?.message ?
                    <p className="text-xs mb-1 text-red-500">{errors.email.message}</p> :
                    <p className="text-xs mb-1 text-slate-500">Email</p>
                }
                <TextField
                    {...register('email', {
                        required: 'Email is required',
                        pattern: {
                            value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                            message: 'Enter a valid email address'
                        }
                    })}
                    fullWidth
                    error={!!errors.email}
                    size="small"
                />
            </div>

            <div className="mb-4">
                {errors.phone?.message ?
                    <p className="text-xs mb-1 text-red-500">{errors.phone.message}</p> :
                    <p className="text-xs mb-1 text-slate-500">Phone Number</p>
                }
                <TextField
                    {...register('phone', {
                        required: 'Phone number is required',
                        pattern: {
                            value: /^\+1[2-9]\d{2}[2-9](?!11)\d{6}$/,
                            message: 'Phone number must be a valid US number with country code (e.g., +12345678901)'
                        }
                    })}
                    fullWidth
                    error={!!errors.phone}
                    size="small"
                />
            </div>

            <div className="mb-4">
                {errors.address?.message ?
                    <p className="text-xs mb-1 text-red-500">{errors.address.message}</p> :
                    <p className="text-xs mb-1 text-slate-500">Address</p>
                }
                <TextField
                    {...register('address', { required: 'Address is required' })}
                    fullWidth
                    multiline
                    rows={4}
                    error={!!errors.address}
                    size="small"
                />
            </div>

            <div>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Submitting...' : 'Submit'}
                </Button>
            </div>
        </form>
    );
}
