import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DataContext } from "../context/data-context";
import { formatPhoneNumber } from "../util/formatPhone";
import { BsFacebook, BsInstagram, BsYoutube, BsTiktok } from "react-icons/bs";
import TGLogo from "../assets/img/tg-logo.png";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../firebase";

export default function Footer() {
  const navigate = useNavigate();
  const { info } = useContext(DataContext);
  const [brands, setBrands] = useState([]);
  const [bodies, setBodies] = useState([]);

  // fetch vehicles from firestore and make a set of unique brands and body types
  const fetchVehicles = useCallback(async () => {
    try {
      const response = await getDocs(collection(db, "Vehicles"));
      const brandsData = new Set();
      const bodyData = new Set();

      response.forEach(doc => {
        const data = doc.data();
        if (data.make) {
          brandsData.add(data.make);
        }

        if (data.body) {
          bodyData.add(data.body);
        }
      });

      const uniqueBrands = Array.from(brandsData);
      const uniqueBodies = Array.from(bodyData);
      setBrands(uniqueBrands);
      setBodies(uniqueBodies);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchVehicles();
  }, [fetchVehicles]);


  // handle navigate to privacy page
  const navigatePrivacyHandler = () => navigate("/privacy-statement");

  return (
    <div className="grid gap-4 p-6 text-white bg-primary">
      <div className="grid gap-2 mx-auto my-4">
        <img src={TGLogo} alt="Logo" className="w-40" />
        <p className="text-xs text-center">TOP GUN AUTOMOBILES</p>
      </div>

      <hr className="w-full border-slate-500 border-1" />

      <div className="grid lg:grid-cols-4 gap-6 my-4">
        <div className="text-center">
          <p className="text-lg font-bold mb-4">Shop by Popular Brands</p>
          <div className="grid grid-cols-2 gap-6 max-w-xs lg:w-full mx-auto">
            <div>
              {brands && brands.length > 0 ? (
                brands.map((brand, index) => (
                  <PageNavigation key={index} title={brand} to={`/search/brand/${brand}`} />
                ))
              ) : null}
            </div>
          </div>
        </div>

        <div className="text-center">
          <p className="text-lg font-bold mb-4">Shop by Body Style</p>
          {bodies && bodies.length > 0 ? (
            bodies.map((body, index) => (
              <PageNavigation key={index} title={body} to={`/search/body/${body}`} />
            ))
          ) : null}
        </div>

        <div className="text-center">
          <p className="text-lg font-bold mb-4">Services Offered</p>
          <PageNavigation title="Buy Vehicle" to="/search" />
          <PageNavigation title="Sell Vehicle" to="/" />
          <PageNavigation title="Get Auto Loan" to="/finance" />
          <div className="mb-1.5">
            <a href="https://poshcars.io/" target="_blank" rel="noreferrer" className="text-sm capitalize hover:underline">Rent Vehicle</a>
          </div>
          <PageNavigation title="Repair Vehicle" to="/" />
        </div>

        <div className="text-center">
          <p className="text-lg font-bold mb-4">Contact Information</p>
          <p className="text-sm mb-1.5">{formatPhoneNumber(info.businessPhone2)}</p>
          <p className="text-sm mb-1.5">{formatPhoneNumber(info.businessPhone1)}</p>
          <p className="text-sm mb-1.5">info@topgunautomobiles.com</p>
          <p className="text-sm mb-1.5">{info.businessAddress}</p>
        </div>
      </div>

      <hr className="w-full border-slate-500 border-1" />

      <div className="items-center justify-between md:flex">
        <p className="text-sm text-center md:text-left">
          © 2024 Top Gun Automobiles™ All Rights Reserved.
        </p>
        <div className="flex flex-col md:flex-row md:gap-8 my-2 md:my-0 items-center justify-center">
          <button onClick={navigatePrivacyHandler} className="text-sm">Privacy Statement</button>
          <div className="flex justify-center my-2 space-x-6">
            <BsFacebook
              onClick={() => window.open(info.facebookURL, "_blank")}
              className="w-6 h-6 text-white hover:opacity-75 cursor-pointer"
            />
            <BsInstagram
              onClick={() => window.open(info.instagramURL, "_blank")}
              className="w-6 h-6 text-white hover:opacity-75 cursor-pointer"
            />
            <BsYoutube
              onClick={() => window.open(info.youtubeURL, "_blank")}
              className="w-6 h-6 text-white hover:opacity-75 cursor-pointer"
            />
            <BsTiktok
              onClick={() => window.open(info.tiktokURL, "_blank")}
              className="w-6 h-6 text-white hover:opacity-75 cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  );
}


// Other page navigation
const PageNavigation = ({ to, title }) => {
  return <div className="mb-1.5">
    <Link to={to}><p className="text-sm capitalize hover:underline">{title}</p></Link>
  </div>
} 