import { useNavigate } from "react-router";
import { getPrice } from "../../util/getPrice";

export default function VehicleCard({ vehicle }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/vehicle?id=${vehicle.vin}`)}
      className="w-full rounded-xl overflow-hidden bg-secondary"
    >
      <div className="p-4 cursor-pointer md:min-h-[320px] transition-all bg-gray-100 hover:bg-gray-200">
        <div>
          <p className="text-sm font-bold text-primary">{vehicle.make}</p>
          <p className="text-lg font-light text-primary">
            {vehicle.year} {vehicle.model}
          </p>
          <p className="text-xs font-bold text-gray-600">
            {vehicle.trim} - {getPrice(vehicle.mileage)}mi
          </p>
        </div>
        <div className="w-full h-[230px] md:h-[200px] lg:h-[180px] mx-auto overflow-hidden rounded-xl my-2">
          <img src={vehicle.images[0]} alt="..." className="min-w-full min-h-full" />
        </div>
        <hr />
        <p className="text-xl font-bold text-center mt-3 text-primary">
          ${getPrice(vehicle.price)}
        </p>
      </div>
      <p className="my-1 text-xs font-semibold text-center text-white">
        TOP GUN CERTIFIED
      </p>
    </div>
  );
}
