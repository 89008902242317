import { FaSearch } from "react-icons/fa";
import { useForm } from "react-hook-form";

export default function SearchBar({ onSubmit }) {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const handleSubmission = (data) => onSubmit(data.query)

    return (
        <div className={`relative rounded-full overflow-hidden shadow-md border bg-white ${errors.query ? "border-red-200" : "border-slate-200"}`}>
            <form onSubmit={handleSubmit(handleSubmission)}>
                <input
                    className="text-sm w-full pl-3 py-2.5 border-none focus:border-transparent focus:ring-0 pr-7"
                    type="text"
                    placeholder="Search make, model or year"
                    {...register("query", { required: true })}
                />
                <button type="submit" className="absolute rounded-full top-[2px] right-[3px] p-2 cursor-pointer bg-white text-secondary hover:text-primary">
                    <FaSearch size={20} />
                </button>
            </form>
        </div>
    );
}
