import { Fragment, useEffect } from "react";
import RentalBannerImage from "../../assets/img/rental-banner.png";
import CarouselBodyStyle from "../CarouselBodyStyle";
import CarouselBrand from "../CarouselBrand";
import Banner from "../Banner";

export default function Home() {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  return (
    <Fragment>
      <Banner />
      <div className="grid gap-16 py-12 mx-4 justify-items-center">
        <CarouselBodyStyle />
        <CarouselBrand />

        <div className="flex items-center w-full border rounded-lg md:w-3/4 ">
          <div className="pl-4 text-xs">
            <p className="font-bold text-secondary md:text-lg">NOW OFFERING:</p>
            <p className="font-bold text-primary md:text-4xl">
              <a
                href="https://poshcars.io/"
                target="_blank"
                rel="noopener noreferrer"
                className="font-bold text-primary md:text-4xl hover:underline"
              >
                Vehicle Subscription
              </a>
            </p>
            <br />
            <p className="text-stone-400 md:text-sm">in partnership with:</p>
            <p className="font-bold text-yellow-600 font-Play md:text-2xl"><a href="https://poshcars.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline">POSH</a></p>
          </div>
          <img src={RentalBannerImage} alt="..." className="w-3/4 ml-auto rounded-lg md:w-1/2" />
        </div>

      </div>
    </Fragment>
  );
}
